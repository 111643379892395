import {
    Button, Datagrid, DateField,
    EditButton, List, NumberField, Pagination, PaginationProps, ReferenceField, ReferenceManyField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useDataProvider, useListPaginationContext,
    useRecordContext
} from "react-admin";
import { ActionDataProvider } from "../../hoc/ApiProvider";
import React, { FC, useState } from "react";
import { CommonFilterCondition } from "../../client";
import CouponStateField from "../../components/CouponStateField";
import AccessCodeStateField from "../../components/AccessCodeStateField";

const AccessCodeActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton />
    </TopToolbar>
};
const AccessCodeShowLayout = (props: any) => {
    const record = useRecordContext()

    const [selectedState, setSelectedState] = useState(null)
    const filters: any = { op: CommonFilterCondition.ARRAYCONTAINS }
    if (selectedState) {
        filters['state'] = selectedState
    }

    return <>
        <TabbedShowLayout {...props}>
            <Tab label="summary">
                <AccessCodeStateField source="state" />
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="email" />
                <NumberField source="quantity" />
                <DateField showTime={true} source="created_at" />
                <DateField showTime={true} source="updated_at" />
                <DateField showTime={true} source="redeemed_at" />
                <TextField source="created_by" />
            </Tab>
        </TabbedShowLayout>
    </>
}

export const AccessCodeShow = (props: ShowProps) => {

    return <Show {...props} actions={<AccessCodeActions />}>
        <AccessCodeShowLayout />
    </Show>
}

export default AccessCodeShow
