import React, {useState} from 'react';
import { WidgetProps, utils } from '@rjsf/core';

import {getUiOptions} from "@rjsf/core/lib/utils";

import {MenuItem, InputLabel, Select, FormControl} from "@mui/material";

const { asNumber, guessType } = utils;

const nums = new Set(['number', 'integer']);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (schema: any, value: any) => {
    // "enum" is a reserved word, so only "type" and "items" can be destructured
    const { type, items } = schema;
    if (value === '' && type !== 'string') {
        return undefined;
    } else if (type === 'array' && items && nums.has(items.type)) {
        return value.map(asNumber);
    } else if (type === 'boolean') {
        return value === 'true';
    } else if (type === 'number') {
        return asNumber(value);
    } else if (type === 'string') {
        return value
    }

    // If type is undefined, but an enum is present, try and infer the type from
    // the enum values
    if (schema.enum) {
        if (schema.enum.every((x: any) => guessType(x) === 'number')) {
            return asNumber(value);
        } else if (schema.enum.every((x: any) => guessType(x) === 'boolean')) {
            return value === 'true';
        }
    }

    return value;
};

const SelectWidget = ({
                          schema,
                          id,
                          options,
                          required,
                          disabled,
                          readonly,
                          value,
                          autofocus,
                          onChange,
                          onBlur,
                          onFocus,
                          rawErrors = [],
                          uiSchema = {}
                      }: WidgetProps) => {

    const { enumOptions, enumDisabled } = options;

    const uiOptions = getUiOptions(uiSchema);
    const textFieldProps = uiOptions.props;




    const defaultLabel = textFieldProps && textFieldProps.label ? textFieldProps.label : (schema.title||"Label");
    const color = textFieldProps && textFieldProps.color ? textFieldProps.color : "primary";



    const [selectOptions, setSelectOptions] = useState([{value : "selectOption", label : "Select option"}, ...enumOptions]);
    const [selectedValue, setSelectedValue] = useState(typeof value === 'undefined' || value === null ? "selectOption" : value)
    const [label, setLabel] = useState(defaultLabel)



    const _onChange = ({ target: { value } } ) =>
    {
        onChange(processValue(schema, value));
        //after an option got selected remove the none from the list
        setSelectOptions(selectOptions.filter(s => s.value !== 'selectOption'))
        setSelectedValue(processValue(schema, value))
    }
    const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
        onBlur(id, processValue(schema, value));
    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
        onFocus(id, processValue(schema, value));

    const emptyItem = selectOptions.find((o) => o.value === '')

    return (
        <FormControl color={color} required={required}>
            <InputLabel id={"label"+id} color={color}>{label}</InputLabel>
            <Select
                labelId={"label"+id}
                label={label}
                id={id}
                value={selectedValue || (emptyItem?.label||"selectOption")}
                renderValue={(val) => {
                    return selectOptions.find((o) => o.value === val)?.label || (emptyItem ? emptyItem.label : val)
                }}
                displayEmpty={true}
                required={required}
                disabled={disabled || readonly}
                autoFocus={autofocus}
                error={rawErrors.length > 0}
                onChange={_onChange}
                onOpen={() => {
                    if(selectOptions.filter(i => i.value === "selectOption").length === 1){
                        setSelectedValue("selectOption")
                        textFieldProps && textFieldProps.shrinkLabel && setLabel(textFieldProps.shrinkLabel)
                    }
                }}
                onBlur={_onBlur}
                onFocus={_onFocus}
                color={color}>
                {(selectOptions as any).map(({ value, label }: any, i: number) => {
                    const d: any = enumDisabled && (enumDisabled as any).indexOf(value) !== -1;
                    return (
                        <MenuItem key={i} value={value} disabled={d}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default SelectWidget;
