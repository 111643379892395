import React, {useState} from 'react';
import "../../styles/sass/forms.scss"

import {Box, Tab, Tabs, Typography} from "@mui/material";

import PillsWidget from "./PillsWidget";
import ArrayFieldTemplate from "./ArrayFieldTemplate";
import ObjectFieldTemplate from "./ObjectFieldTemplate"
import {Theme5} from '@rjsf/material-ui';
import {withTheme} from "@rjsf/core";
import validator from "@rjsf/validator-ajv8"
import FieldTemplate from "./FieldTemplate";
import CustomFieldTemplate from "./CutomFIeldTemplate";
import BaseInputTemplate from "./BaseInputTemplate";
import SelectWidget from "./SelectWidget";
import TextWidget from "./TextWidget";
import DescriptionField from "./DescriptionField";
import TitleField from "./TitleField";
import CheckboxWidget from "./CheckboxWidget";
import RadioWidget from "./RadioWidget"
import ErrorListTemplate from "./ErrorListTemplate";

const ThemedForm = withTheme({
    ...Theme5,
    ObjectFieldTemplate: ObjectFieldTemplate,
    //FieldTemplate : FieldTemplate,
    //BaseInputTemplate : BaseInputTemplate,
    //ErrorListTemplate: ErrorListTemplate,
    ArrayFieldTemplate: ArrayFieldTemplate,
    widgets: {
        ...Theme5.widgets,
        SelectWidget : SelectWidget,
        TextWidget : TextWidget,
        CheckboxWidget : CheckboxWidget,
        RadioWidget : RadioWidget,
        pills: PillsWidget,
    }
});


const JsonSchemaForm = React.forwardRef(({schema: {$id, ...schema}, box = {}, uiSchema = {}, data = {}, submitText = null, noSubmit=false, ...props}, ref) => {
    const boxProps = {...box}

    const submitButtonOptions = {
        "ui:submitButtonOptions": {
            "props":      {
                "disabled":  false,
                //"color" : "secondary"
                //"className": "btn btn-info",
            },
            "norender":   noSubmit,
            "submitText": submitText||"Submit"
        },

    }

    const transformErrors = (errors) => {
        let filteredErrors = []
        //we want to avoid duplicated errors
        for(let i = 0; i < errors.length; i++){
            const exist = filteredErrors.filter(e => e.property === errors[i].property)
            if(exist.length === 0 && (errors[i].params.allowedValue === undefined) && ((errors[i].params.passingSchemas !== null))){
                filteredErrors.push(errors[i])
            }
        }
        filteredErrors = filteredErrors.sort((x, y) => {
            if(x.property.includes("aspect")){
                return -1
            }else if(x.property.includes("video") && !y.property.includes("aspect")){
                return -1
            }else{
                return 1
            }
        })
        return filteredErrors
    }

    uiSchema = {...uiSchema, ...submitButtonOptions}
    //onError={() => console.log('form validation error')}
    return <Box className="JsonSchemaForm" {...boxProps}>
        <ThemedForm ref={ref} schema={schema}
                    //onChange={props.onChange}
                    //templates={{ ErrorListTemplate: ErrorListTemplate }}
                    uiSchema={uiSchema}
                    formData={data}
                    showErrorList={false}
                    validator={validator}
                    liveValidate={props.liveValidate}
                    //templates={ ErrorListTemplate }
                    transformErrors={transformErrors}
                    noHtml5Validate={true} action="" {...props}/>
        {/*        <MaterialJsonSchemaForm
            schema={schema}
            uiSchema={uiSchema}
            xhrSchema={{}} // Optional
            formData={formData}
            onChange={({formData}) => setFormData(formData)}
            onSubmit={(submittedData) => console.log('form submitted', submittedData)}
            {...props}
        />*/}
    </Box>;
});


export default JsonSchemaForm;
