import React from 'react';
import { AutocompleteInput, Create, CreateProps, Edit, EditProps, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const AccessCodeCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm sx={{ maxWidth: '400px' }}>
      <TextInput disabled label="Id" source="id" name="id" />
      <TextInput label="Email" source="email" name="email" />
      <TextInput label="Access Code" source="code" name="code" />
      <TextInput sx={{ display: 'none' }} source="created_by" value={"admin"} defaultValue={"admin"} />
      <NumberInput label="Quantity" source="quantity" helperText="Number of times the access code can be used" validate={required()} defaultValue={1} name="quantity" />
    </SimpleForm>
  </Create>
);

export const AccessCodeEdit = (props: EditProps) => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" name="id" />
      <TextInput label="Email" source="email" name="email" />
      <TextInput label="Access Code" source="code" name="code" />
      <NumberInput source="amount" validate={required()} defaultValue={1} name="quantity" />
    </SimpleForm>
  </Edit>
);

export * from './ListView'
export * from './ShowView'
