import { Datagrid, DateField, List, ListActions, ShowButton, TextField, SelectInput, NumberField, ReferenceField, TextInput } from "react-admin";
import React from "react";
import AccessCodeStateField from "../../components/AccessCodeStateField";


const accessCodeStates = [
    { id: 0, name: 'New' },
    { id: 10, name: 'Enabled' },
    { id: 20, name: 'Redeemed' },
    { id: 255, name: 'Canceled' }
];

const accessCodeFilters = [
    <SelectInput label="State" source="state" name="state" choices={accessCodeStates} />,
    <TextInput label="Access Code" source="access_code" name="code" />,
    <TextInput label="Email" source="email" name="email" />,
];


export const AccessCodeList = () => (
    <List actions={<ListActions />} filters={accessCodeFilters}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="code" />
            <AccessCodeStateField source="state" />
            <TextField source="email" />
            <NumberField source="quantity" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <ShowButton />
        </Datagrid>
    </List>
);
