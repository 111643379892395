import {createTheme, Theme} from '@mui/material/styles';

import colors from '../../styles/sass/colors/colors-variables.module.scss'
import fontFamily from '../../styles/sass/typography/fontFamily-variables.module.scss'
import fontSize from '../../styles/sass/typography/fontSize-variables.module.scss'
import fontStyle from '../../styles/sass/typography/fontStyle-variables.module.scss'
import fontWeight from '../../styles/sass/typography/fontWeight-variables.module.scss'
import lineHeight from '../../styles/sass/typography/lineHeight-variables.module.scss'
import {Breakpoint, InputBaseProps} from "@mui/material"
import {ExpandMore} from "@mui/icons-material";
import {
  checkActive,
  checkColor,
  checkCompleted,
  checkDisabled,
  checkSubvariant,
  checkVariant,
  ExtendedTypographyOptions,
  IThemeOptions,
  MuiButtonStyleOverride,
  OwnerState
} from "../utils";



// A custom theme for this app
export const byteniteTheme = createTheme({
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px black inset"
    },
    "&:-internal-autofill-selected": {
      backgroundColor: colors.white
    }
  },
  typography: {
    body1: {
      fontFamily: fontFamily.body1,
      fontSize: fontSize.body1,
      fontStyle: fontStyle.body1,
      fontWeight: fontWeight.body1,
      lineHeight: lineHeight.body1
    },
    body2: {
      fontFamily: fontFamily.body2,
      fontSize: fontSize.body2,
      fontStyle: fontStyle.body2,
      fontWeight: fontWeight.body2,
      lineHeight: lineHeight.body2
    },
    button1: {
      fontFamily: fontFamily.button1,
      fontSize: fontSize.button1,
      fontStyle: fontStyle.button1,
      fontWeight: fontWeight.button1,
      lineHeight: lineHeight.button1
    },
    button2: {
      fontFamily: fontFamily.button2,
      fontSize: fontSize.button2,
      fontStyle: fontStyle.button2,
      fontWeight: fontWeight.button2,
      lineHeight: lineHeight.button2
    },
    h1: {
      fontFamily: fontFamily.heading1,
      fontSize: fontSize.heading1,
      fontStyle: fontStyle.heading1,
      fontWeight: fontWeight.heading1,
      lineHeight: lineHeight.heading1
    },
    h2: {
      fontFamily: fontFamily.heading2,
      fontSize: fontSize.heading2,
      fontStyle: fontStyle.heading2,
      fontWeight: fontWeight.heading2,
      lineHeight: lineHeight.heading2
    },
    h3: {
      fontFamily: fontFamily.heading3,
      fontSize: fontSize.heading3,
      fontStyle: fontStyle.heading3,
      fontWeight: fontWeight.heading3,
      lineHeight: lineHeight.heading3
    },
    caption1: {
      fontFamily: fontFamily.caption1,
      fontSize: fontSize.caption1,
      fontStyle: fontStyle.caption1,
      fontWeight: fontWeight.caption1,
      lineHeight: lineHeight.caption1
    },
    caption2: {
      fontFamily: fontFamily.caption2,
      fontSize: fontSize.caption2,
      fontStyle: fontStyle.caption2,
      fontWeight: fontWeight.caption2,
      lineHeight: lineHeight.caption2
    },
    label1: {
      fontFamily: fontFamily.label1,
      fontSize: fontSize.label1,
      fontStyle: fontStyle.label1,
      fontWeight: fontWeight.label1, // TODO: check typography design - not working
      lineHeight: lineHeight.label1
    },
    label2: {
      fontFamily: fontFamily.label2,
      fontSize: fontSize.label2,
      fontStyle: fontStyle.label2,
      fontWeight: fontWeight.label2,
      lineHeight: lineHeight.label2
    },
    h6: {
      fontFamily: fontFamily.chip,
      fontSize: fontSize.chip,
      fontStyle: fontStyle.chip,
      fontWeight: fontWeight.chip,
      lineHeight: lineHeight.chip
    },
    fontFamily: [
      'Gilroy',
      'Gilroy-Regular',
      'Gilroy-SemiBold',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  } as ExtendedTypographyOptions,
  palette: {
    primary: {
      main: colors.pink3,
      dark: colors.pink1,
      light: colors.pink5,
      A200: colors.pink2,
      A400: colors.pink4
    },
    secondary: {
      main: colors.blue3,
      dark: colors.blue1,
      light: colors.blue5,
      A200: colors.blue2,
      A400: colors.blue4
    },
    grey: {
      "100": colors.grey1,
      "200": colors.grey2,
      "300": colors.grey3,
      "400": colors.grey4,
      "500": colors.grey5,
    },
    info: {
      main: colors.yellow3,
      dark: colors.yellow1,
      light: colors.yellow5,
      A200: colors.yellow2,
      A400: colors.yellow4
    },
    success: {
      main: colors.green3,
      dark: colors.green1,
      light: colors.green5,
      A200: colors.green2,
      A400: colors.green4
    },
    error: {
      main: colors.red3,
      dark: colors.red1,
      light: colors.red5,
      A200: colors.red2,
      A400: colors.red4
    },
    warning: {
      main: '#FF5C00',
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          label1: "label",
          label2: "label",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => {

          const styleOverride: MuiButtonStyleOverride = {
            borderRadius: "8px",
            textTransform: "none"
          }

          // DesignSystem - size
          if (ownerState.size === "small") {
            styleOverride.width = "95px";
            styleOverride.height = "34px";
            styleOverride.fontFamily = fontFamily.button2 + " !important";
            styleOverride.fontStyle = fontStyle.button2;
            styleOverride.fontWeight = fontWeight.button2;
            styleOverride.fontSize = fontSize.button2;
            styleOverride.lineHeight = lineHeight.button2;
          }
          if (ownerState.size === "large") {
            styleOverride.width = "131px";
            styleOverride.height = "40px";
            styleOverride.fontFamily = fontFamily.button1 + " !important";
            styleOverride.fontStyle = fontStyle.button1;
            styleOverride.fontWeight = fontWeight.button1;
            styleOverride.fontSize = fontSize.button1;
            styleOverride.lineHeight = lineHeight.button1;
          }

          // DesignSystem - primary
          if (ownerState.variant === "contained") {
            // mouseover
            styleOverride["&:hover"] = {
              background: colors.pink2
            }
            // mouseclick
            styleOverride["&:active"] = {
              background: colors.pink1
            }
          }

          // DesignSystem - secondary
          if (ownerState.variant === "outlined") {

            if (checkSubvariant(ownerState, "subvariant-white")) {
              styleOverride.color = colors.white
              styleOverride.borderColor = colors.white
              // mouseover
              styleOverride["&:hover"] = {
                color: colors.white,
                borderColor: colors.white,
              };
              // mouseclick
              styleOverride["&:active"] = {
                color: colors.white,
                borderColor: colors.white,
              }
            } else {
              styleOverride.background = colors.white
              // mouseover
              styleOverride["&:hover"] = {
                color: colors.pink2,
                background: colors.white,
                borderColor: colors.pink2,
              };
              // mouseclick
              styleOverride["&:active"] = {
                color: colors.pink1,
                background: colors.white,
                borderColor: colors.pink1,
              }
            }

          }

          // DesignSystem - tertiary
          if (ownerState.variant === "text") {
            if (checkSubvariant(ownerState, "subvariant-white")) {
              styleOverride.color = colors.white
              styleOverride.borderColor = colors.white
              styleOverride.textDecoration = 'underline'
              // mouseover
              styleOverride["&:hover"] = {
                color: colors.white,
                borderColor: colors.white,
              };
              // mouseclick
              styleOverride["&:active"] = {
                color: colors.white,
                borderColor: colors.white,
              }
            } else {
              // mouseover
              styleOverride["&:hover"] = {
                color: colors.pink2,
                background: colors.white,
              }
              // mouseclick
              styleOverride["&:active"] = {
                color: colors.pink1,
                background: colors.white,
              }
            }
          }

          // DISABLED
          if (ownerState.disabled) {
            styleOverride["&:disabled"] = {
              color: colors.grey3
            }

            styleOverride[" .icon-path"] = {
              fill: colors.grey3,
              stroke: colors.grey3
            }

            // DesignSystem - primary
            if (ownerState.variant === "contained") {
              styleOverride["&:disabled"].backgroundColor = colors.grey4
            }

            // DesignSystem - secondary
            if (ownerState.variant === "outlined") {
              styleOverride["&:disabled"].borderColor = colors.grey3
            }

          }

          return styleOverride
        },
        textError: {
          color: colors.red3
        }
      }
    },
    MuiIcon: {
      defaultProps: {
        // baseClassName : "fas"
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ownerState}) => {
          if (checkSubvariant(ownerState, "subvariant-white")) {
            return {
              color: colors.white,
              "&.Mui-checked:hover": {
                color: colors.white
              },
              "&.Mui-checked": {
                color: colors.white
              }
            }
          }
          if (checkColor(ownerState as OwnerState, "primary")) {
            return {
              color: colors.pink3,
              "&.Mui-checked:hover": {
                color: colors.pink1
              }
            }
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontStyle: fontStyle.button2 + " !important",
          fontWeight: fontWeight.button2 + " !important",
          fontSize: fontSize.button2 + " !important",
          lineHeight: lineHeight.button2 + " !important"
        },
        fontSizeLarge: {
          fontStyle: fontStyle.button1 + " !important",
          fontWeight: fontWeight.button1 + " !important",
          fontSize: fontSize.button1 + " !important",
          lineHeight: lineHeight.button1 + " !important"
        },
      },
      defaultProps: {
        // baseClassName : "fas"
      },
      /*variants: [
          {
              props: {fontSize: 'xl'},
              style: {
                  fontSize: '4rem',
                  width: '4rem',
                  height: '4rem'
              },
          },
          {
              props: {fontSize: 'xxl'},
              style: {
                  fontSize: '6rem',
                  width: 'auto',
                  height: 'auto'
              },
          },
      ],*/
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMore
      },
      styleOverrides: {
        iconOutlined: {
          color: colors.pink3
        },
        iconOpen: {
          color: colors.pink2
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: () => {
          return {
            width: "146px",
            height: "18px",
            fontFamily: fontFamily.caption1,
            fontStyle: fontStyle.caption1,
            fontWeight: fontWeight.caption1,
            fontSize: fontSize.caption1,
            lineHeight: lineHeight.caption1,
          }
        },
        shrink: ({ownerState}) => {
          const styleOverride: Record<string, unknown> = {
            transform: 'translate(14px, -8px) scale(0.8) !important',
          }

          if (checkColor(ownerState, "secondary")) {
            styleOverride.color = colors.blue2
          }

          if (checkColor(ownerState, "error")) {
            styleOverride.color = colors.red3
          }

          if (checkSubvariant(ownerState, "subvariant-white")) {
            styleOverride.color = colors.white + " !important"
          }

          return styleOverride

        },
        outlined: ({ownerState}) => {
          const styleOverride: Record<string, unknown> = {
            transform: 'translate(16px, 8px) scale(1)',
          }

          if (checkDisabled(ownerState)) {
            styleOverride.color = colors.grey4 + " !important"
          }

          if (checkSubvariant(ownerState, "subvariant-white")) {
            styleOverride.color = colors.white
            styleOverride.transform = "translate(16px, 8px) scale(1)"
          }

          return styleOverride

        },
        /*asterisk : {
            display : 'none'
        }*/
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          width: "78px",
          marginRight: "-12.5px",
          zIndex: 1
        },
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ":disabled": colors.grey4,
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ownerState}) => {
          if (checkSubvariant(ownerState, "subvariant-round")) {
            return {
              // width: "5vh",
              // height: "5vh",
              borderRadius: "128px"
            }
          } else {
            return {
              borderRadius: "16px"
            }
          }
        },
        elevation3: {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        gutters: {
          color: colors.pink3,
          // "&,Mui"
          "&:hover": {
            backgroundColor: colors.pink5
          },
          "&.Mui-selected": {
            backgroundColor: colors.pink5 + " !important"
          },
          "&.Mui-selected:hover": {
            color: colors.pink1
          },
          "&.Mui-disabled": {
            color: colors.grey3
          }
        },
        root: {
          // width : "189px",
          minHeight: "22px",
          maxHeight: "30px",
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "10px",
          marginBottom: "10px",
          padding: "4px 12px",
          borderRadius: "16px",
          // fontFamily : fontFamily.button2 + " !important",
          fontStyle: fontStyle.button2 + " !important",
          fontWeight: fontWeight.button2 + " !important",
          fontSize: fontSize.button2 + " !important",
          lineHeight: lineHeight.button2 + " !important",
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ownerState}) => {

          const styleOverride: Record<string, unknown> = {
            borderWidth: "1px !important"
          };

          if (checkVariant(ownerState, "outlined")) {
            styleOverride.borderColor = colors.grey2;
          }

          if (checkColor(ownerState, "secondary")) {
            const ownerStateValue: any = ownerState.value
            if (ownerStateValue && ownerStateValue !== "none" && ownerStateValue.length > 0) {
              styleOverride.borderColor = colors.blue2 + " !important";
            }
          }

          if (checkColor(ownerState, "error")) {
            styleOverride.borderColor = colors.red3 + " !important";
          }

          if (checkColor(ownerState, "primary") && ownerState.name === "endAdornment") {
            styleOverride.borderColor = colors.pink3 + " !important";
          }

          if (checkDisabled(ownerState)) {
            styleOverride.borderColor = colors.grey4 + " !important";
          }

          if (checkSubvariant(ownerState, "subvariant-white")) {
            styleOverride.borderColor = colors.white + " !important"
          }

          return styleOverride

        },
        root: ({ownerState}) => {
          if (checkColor(ownerState, "primary") && ownerState.name === "endAdornment") {
            return {
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
              background: "white" // TODO: use theme background
            }
          }

          return {
            borderRadius: "8px"
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ownerState}: { ownerState: InputBaseProps & Record<string, unknown>, theme: Omit<Theme, "components"> }) => {

          const styleOverride: Record<string, unknown> = {
            height: "34px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 4px",
            fontFamily: fontFamily.caption1,
            fontStyle: fontStyle.caption1,
            fontWeight: fontWeight.caption1,
            fontSize: fontSize.caption1,
            lineHeight: lineHeight.caption1,
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px white inset"
            },
          }

          if (ownerState.endAdornment !== undefined && !ownerState.fullWidth) {
            styleOverride.width = "177px"
          } else {
            if (!ownerState.fullWidth) {
              // TODO: not working on selects
              // styleOverride["width"] = "238px"
            }
          }

          if (checkVariant(ownerState, "outlined")) {
            styleOverride.color = colors.grey2;
            if (ownerState.value && ownerState.value === "none") {
              styleOverride["&.Mui-focused"] = {
                "color": colors.blue3 + " !important"
              }
            }
            if (ownerState.value === undefined || ownerState.value && ownerState.value === "") {
              styleOverride["&.Mui-focused"] = {
                "color": colors.blue4
              }
            }
          }

          if (checkColor(ownerState, "secondary")) {
            const ownerStateValue: any = ownerState.value
            if (ownerStateValue && ownerStateValue.length > 0) {
              styleOverride.color = colors.black;
            }
          }

          if (checkColor(ownerState, "error")) {
            styleOverride.color = colors.red3;
          }

          if (checkColor(ownerState, "primary") && ownerState.name === "endAdornment") {
            styleOverride.color = colors.pink3;
          }

          if (checkDisabled(ownerState)) {
            // styleOverride["&:disabled"] = {color : colors.grey4 + " !important"}
            // styleOverride["&.MuiDisabled"] = {color : colors.grey4 + " !important"}
            styleOverride.color = colors.grey4 + " !important";
          }

          if (checkSubvariant(ownerState, "subvariant-white")) {
            styleOverride["&.Mui-focused"] = {
              "color": colors.white + " !important"
            }
            styleOverride.color = 'rgba(255, 255, 255, 0.8)'
          }

          return styleOverride
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: (ownerState) => {
          if (checkColor(ownerState, "primary")) {
            return {
              borderRadius: "0px",
              background: colors.appbarGradient,
              display: "flex",
              alignItems: "end",
            }
          }
        },
        colorSecondary: {
          borderRadius: '0'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1Determinate: (ownerState) => {
          if (checkColor(ownerState, "primary")) {
            return {
              borderRadius: "0px",
              background: colors.progressBarGradient,
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
              height: "15px"
            }
          } else if (checkColor(ownerState, "secondary"))  {
            return {
              borderRadius: "0px",
            }
          }
        },
        root: (ownerState) => {
          if (checkColor(ownerState, "primary")) {
            return {
              borderRadius: "0px",
              background: colors.grey4,
              height: "15px"
            }
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.blue3,
          color: colors.white,
          borderRadius: "0px",
          boxShadow: "4px 0px 10px rgba(0, 0, 0, 0.25)"
        },

      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: (ownerState) => {
          if (checkSubvariant(ownerState, "subvariant-white")) {
            return {
              color: colors.white
            }
          }
        },
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: (ownerState) => {
          if (checkSubvariant(ownerState, "subvariant-white")) {
            return {
              color: colors.white
            }
          }
        },
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1800px",
          marginTop: "2%"
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: "100px"
        },
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey5,
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: (ownerState: OwnerState) => {

          const styleOverride: Record<string, unknown> = {
            borderBottomWidth: "0px"
          }

          const className = ownerState.className as string || ''

          if (ownerState && ownerState.className && className.includes("table-header-left-cell")) {
            styleOverride.borderTopLeftRadius = "8px";
            styleOverride.borderBottomLeftRadius = "8px";
          }

          if (ownerState && ownerState.className && className.includes("table-header-right-cell")) {
            styleOverride.borderTopRightRadius = "8px";
            styleOverride.borderBottomRightRadius = "8px";
          }

          return styleOverride
        },
        root: {
          height: "35px",
          paddingTop: "0px",
          paddingBottom: "0px",
          borderBottomColor: colors.blue5
        },
        sizeCompact: {
          height: '18px',
          padding: '0 8px',
          fontSize: '0.75rem',
          lineHeight: '1.66',
          verticalAlign: 'bottom'
        }
      }
    },

    MuiDivider: {
      styleOverrides: {
        primary: {
          borderColor: colors.pink3
        },
        secondary: {
          borderColor: colors.blue1
        }
      }
    },
    MuiStepper: {
      styleOverrides: {}
    },
    MuiStep: {
      styleOverrides: {}
    },
    MuiStepConnector: {
      styleOverrides: {
        line: (ownerState) => {
          if (checkActive(ownerState) || checkCompleted(ownerState)) {
            return {
              borderColor: colors.pink3,
            }
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: colors.pink2
          }
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          "&.Mui-active": {
            color: colors.pink2
          },
          "&.Mui-completed": {
            color: colors.pink3
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        },
      }
    },
    MuiTabs: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: colors.white
        },
        indicator: {
          backgroundColor: colors.white
        }
      }
    },
    MuiModal: {
      styleOverrides: {}
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: colors.pink3,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: colors.pink3,
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: colors.grey1
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.7
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
          },
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: (ownerState) => {
          if (checkSubvariant(ownerState, "subvariant-samplevideo")) {
            return {
              borderRadius: "8px",
              marginLeft: "1em"
            }
          }
          return {
            "borderRadius": "16px"
          }
        },
        sizeSmall: {
          borderRadius: '8px',
        },
        filledPrimary: {
          color: colors.pink2,
          border: `1px solid ${colors.pink2}`,
          backgroundColor: colors.pink5,
        },
        labelSmall: {
          textOverflow: 'initial'
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        svg: {
          strokeLinecap: "round",
        },
        circle: (ownerState) => {
          if (ownerState && checkSubvariant(ownerState, "subvariant-white")) {
            return {
              color: "white"
            }
          }
          if (ownerState && checkColor(ownerState, "grey")) {
            return {
              color: colors.grey4
            }
          }
          if (ownerState && checkColor(ownerState, "warning")) {
            return {
              color: "#FECB01"
            }
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'none',
          borderRadius: '16px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
          'a:visited': {
            color: colors.yellow2
          },
          'a': {
            color: colors.yellow3
          }
        },
      }
    },
  },
  sidebar: {
    breakpoint: 'md',
    width: 240
  },
  sidebarClosed: {
    width: 73
  }
} as IThemeOptions);

export default byteniteTheme
