import React, {useState} from 'react';


import {Box, Chip, FormLabel} from '@mui/material';
import {WidgetProps} from "@rjsf/core";


const RadioWidget = ({
                         id,
                         schema,
                         options,
                         value,
                         required,
                         disabled,
                         readonly,
                         label,
                         onChange,
                         onFocus,
                     }: WidgetProps) => {
    //const { FormControlLabel, FormLabel, Radio, RadioGroup } = useContext(MuiComponentContext);
    const { enumOptions, enumDisabled } = options;

    const [selectedValue, setSelectedValue] = useState(value)

    const _onChange = (e: any, value: any) => onChange(schema.type == 'boolean' ? value !== 'false' : value);
    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);


    const onClick = (e: any, value: any) => {
        _onChange(e, value)
        setSelectedValue(value)
    }

    return (
        <>
            {schema.title ? <FormLabel required={required} htmlFor={id}>
                {label || schema.title}
            </FormLabel>: null}
            <Box display="flex" flexWrap="wrap" onFocus={_onFocus}>
                {(enumOptions as any).map((option: any, i: number) => {
                    const selected = option.value === selectedValue
                    const itemDisabled = enumDisabled && (enumDisabled as any).indexOf(option.value) !== -1;

                    return <Box pr={1} key={i}>
                        <Chip
                            size="small"
                            color="primary"
                            onClick={(e) => onClick(e, option.value)}
                            label={`${option.label}`}
                            variant={selected ? "filled":"outlined"}
                            disabled={disabled || itemDisabled || readonly}/>
                    </Box>
                })}
            </Box>
        </>
    );
};

export default RadioWidget;
