const schema = {
    "title": "Video transcoding",
    "properties": {
        "output_code_template": {"ui:widget": "hidden"},
        "output_template": {"ui:widget": "hidden"},
        "outputs": {
            "type": "array",
            "items": {
                "$ref": "#/definitions/output"
            }
        }
    },
    "required": [
        "outputs"
    ],
    "definitions": {
        "output": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "output_type": {
                    "title": "Output",
                    "type": "string",
                    "oneOf": [
                        {"const" : "mp4", "title": "MP4"},
                        {"const" : "mkv", "title": "MKV"},
                        {"const" : "webm", "title": "WebM"},
                    ]
                }
            },
            "dependencies": {
                "output_type": {
                    "properties": {
                        "output_type": {
                            "not": {"const": ""}
                        },
                        "output_params": {
                            "$ref": "#/definitions/video_transcoding"
                        }
                    },
                    "required":   ["output_params"]
                }
            },
            "required": [
                "output_type"
            ]
        },
        "video_transcoding": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "aspect": {
                    "$ref": "#/definitions/aspect"
                },
                "video": {
                    "$ref": "#/definitions/video"
                },
                "audio": {
                    "$ref": "#/definitions/audio"
                },
                "filters": {
                    "$ref": "#/definitions/filters"
                }
            },
            "required": [
                "aspect",
                "audio",
                "filters",
                "video"
            ]
        },
        "aspect": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "cropping": {
                    "$ref": "#/definitions/cropping"
                },
                "orientation": {
                    "$ref": "#/definitions/orientation"
                },
                "resolution": {
                    "$ref": "#/definitions/resolution"
                },
                "padding": {
                    "$ref": "#/definitions/padding"
                }
            },
            "required": [
                "cropping",
                "orientation",
                "padding",
                "resolution"
            ],
            "title": "Aspect"
        },
        "cropping": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "left": {
                    "type": "number",
                    "default": 0
                },
                "top": {
                    "type": "number",
                    "default": 0
                },
                "right": {
                    "type": "number",
                    "default": 0
                },
                "bottom": {
                    "type": "number",
                    "default": 0
                }
            },
            "required": [
                "bottom",
                "left",
                "right",
                "top"
            ],
            "title": "Cropping"
        },
        "orientation": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "flip": {
                    "type": "string",
                    "default": "",
                    "oneOf": [
                        {
                            "const": "",
                            "title": "None"
                        },
                        {
                            "const": "horizontal",
                            "title": "Horizontal"
                        },
                        {
                            "const": "vertical",
                            "title": "Vertical"
                        }
                    ]
                },
                "rotation": {
                    "type": "string",
                    "default": "",
                    "oneOf": [
                        {
                            "const": "",
                            "title": "None"
                        },
                        {
                            "const": "clockwise",
                            "title": "90° clockwise"
                        },
                        {
                            "const": "counter-clockwise",
                            "title": "90° counter-clockwise"
                        },
                        {
                            "const": "180",
                            "title": "180°"
                        }
                    ]
                }
            },
            "required": [],
            "title": "Orientation"
        },
        "padding": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "final_aspect_ratio": {
                    "title": "Final aspect ratio",
                    "type": "string",
                    "default": "",
                    "oneOf": [
                        {
                            "const": "",
                            "title": "Don't fill"
                        },
                        {
                            "const": "16:9",
                            "title": "16:9"
                        },
                        {
                            "const": "4:3",
                            "title": "4:3"
                        },
                        {
                            "const": "1:1",
                            "title": "1:1"
                        },
                        {
                            "const": "custom",
                            "title": "Custom"
                        }
                    ]
                },
                "fill_color": {
                    "title": "Fill color",
                    "type": "string",
                    "pattern": "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                }
            },
            "dependencies":{
                "final_aspect_ratio": {
                    "oneOf": [
                        {
                            "properties": {
                                "final_aspect_ratio": {
                                    "const": "custom"
                                },
                                "final_aspect_ratio_x": {
                                    "title": "X",
                                    "type": "number",
                                    "decimal": false
                                },
                                "final_aspect_ratio_y": {
                                    "title": "Y",
                                    "type": "number",
                                    "decimal": false
                                }
                            },
                            "required": [
                                "final_aspect_ratio",
                                "final_aspect_ratio_x",
                                "final_aspect_ratio_y"
                            ]
                        },
                        {
                            "properties": {
                                "final_aspect_ratio": {
                                    "const": ""
                                },
                                "fill_color": {
                                    "format":"hidden"
                                }

                            },
                            "required": []
                        }
                    ]
                }
            },
            "required": [],
            "title": "Padding"
        },
        "resolution": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "height": {
                    "type": "string",
                    "oneOf": [
                        {"const" : "360p", "title": "360p"},
                        {"const" : "480p", "title": "480p"},
                        {"const" : "540p", "title": "540p"},
                        {"const" : "720p", "title": "720p"},
                        {"const" : "1080p", "title": "1080p"},
                        {"const" : "1440p", "title": "1440p"},
                        {"const" : "2160p", "title": "2160p"},
                        {"const" : "custom", "title": "Custom"}
                    ]
                },
                "aspect_ratio": {
                    "title": "Aspect ratio",
                    "type": "string",
                    "default": null,
                    "oneOf": [
                        {
                            "const": "",
                            "title": "Same as source"
                        },
                        {
                            "const": "16:9",
                            "title": "16:9"
                        },
                        {
                            "const": "4:3",
                            "title": "4:3"
                        },
                        {
                            "const": "custom",
                            "title": "Custom"
                        }
                    ]

                },
                "avoid_upscaling": {
                    "type": "boolean",
                    "title": "Avoid upscaling",
                    "default": false
                }
            },
            "dependencies":{
                "height": {
                    "oneOf": [
                        {
                            "properties": {
                                "height": {
                                    "const": "custom"
                                },
                                "custom_height": {
                                    "title": "Height",
                                    "type": "number",
                                    "min": 0,
                                    "decimal": false
                                }
                            },
                            "required": [
                                "custom_height"
                            ]
                        },
                        {
                            "properties": {
                                "height": {
                                    "not": { "const": "custom" }
                                }
                            },
                            "required": ["height"]
                        }
                    ]
                },
                "aspect_ratio": {
                    "oneOf": [
                        {
                            "properties": {
                                "aspect_ratio": {
                                    "const": "custom"
                                },
                                "custom_aspect_ratio": {
                                    "title": "Width",
                                    "type": "number",
                                    "decimal": false
                                }
                            },
                            "required": [
                                "custom_aspect_ratio"
                            ]
                        },
                        {
                            "properties": {
                                "aspect_ratio": {
                                    "not": { "const": "custom" }
                                }
                            },
                            "required": []
                        }
                    ]
                }
            },
            "required": [
                "avoid_upscaling",
                "height"
            ],
            "title": "Resolution"
        },
        "audio": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "audio_codec": {
                    "type": "string",
                    "oneOf": [
                        {"const" : "AAC", "title": "AAC"},
                        {"const" : "AC3", "title": "AC3"},
                        {"const" : "MP3", "title": "MP3"}
                    ]
                },
                "audio_channel": {
                    "type": "string",
                    "oneOf": [
                        {"const" : "1.0", "title": "1.0 - Mono"},
                        {"const" : "2.0", "title": "2.0 - Stereo"},
                        {"const" : "5.1", "title": "5.1 - Dolby Surround"}
                    ]
                },
                "audio_sample_rate": {
                    "type": "string",
                    "oneOf": [
                        {"const" : "12khz", "title": "12 kHZ"},
                        {"const" : "16khz", "title": "16 kHZ"},
                        {"const" : "24khz", "title": "24 kHZ"},
                        {"const" : "32khz", "title": "32 kHZ"},
                        {"const" : "48khz", "title": "48 kHZ"},
                        {"const" : "custom", "title": "Custom"}
                    ]
                },
                "audio_bitrate": {
                    "type": "string",
                    "oneOf": [
                        {"const" : "64k", "title": "64 kbps"},
                        {"const" : "80k", "title": "80 kbps"},
                        {"const" : "96k", "title": "96 kbps"},
                        {"const" : "128k", "title": "128 kbps"},
                        {"const" : "160k", "title": "160 kbps"},
                        {"const" : "192k", "title": "192 kbps"},
                        {"const" : "256k", "title": "256 kbps"},
                        {"const" : "320k", "title": "320 kbps"},
                        {"const" : "custom", "title": "Custom"}
                    ]
                }
            },
            "dependencies": {
                "audio_sample_rate": {
                    "oneOf": [
                        {
                            "properties": {
                                "audio_sample_rate":        {
                                    "const": "custom"
                                },
                                "audio_sample_rate_custom": {
                                    "title": "Sample rate",
                                    "type": "integer"
                                }
                            },
                            "required":   [
                                "audio_sample_rate_custom"
                            ]
                        },
                        {
                            "properties": {
                                "audio_sample_rate": {
                                    "not": {"const": "custom"}
                                }
                            },
                            "required":   []
                        }
                    ]
                },
                "audio_bitrate": {
                    "oneOf": [
                        {
                            "properties": {
                                "audio_bitrate":        {
                                    "const": "custom"
                                },
                                "audio_bitrate_custom": {
                                    "title": "Audio bitrate",
                                    "type": "integer"
                                }
                            },
                            "required":   [
                                "audio_bitrate_custom"
                            ]
                        },
                        {
                            "properties": {
                                "audio_bitrate": {
                                    "not": {"const": "custom"}
                                }
                            },
                            "required":   []
                        }
                    ]
                }
            },
            "required": [
                "audio_bitrate",
                "audio_channel",
                "audio_codec",
                "audio_sample_rate"
            ],
            "title": "Audio"
        },
        "filters": {
            "type": "object",
            "additionalProperties": true,
            "title": "Filters"
        },
        "video": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "codec": {
                    "type": "string",
                    "oneOf": [
                        {"const" : "h264", "title": "H.264 (AVC)"},
                        {"const" : "h265 (HEVC)", "title": "H.265 (HEVC)"},
                        {"const" : "vp8", "title": "VP8"},
                        {"const" : "vp9", "title": "VP9"},
                        {"const" : "av1", "title": "AV1"}
                    ]
                },
                "frame_rate": {
                    "$ref": "#/definitions/frame_rate"
                },
                "bitrate": {
                    "$ref": "#/definitions/bitrate"
                }
            },
            "required": [
                "bitrate",
                "codec",
                "frame_rate"
            ],
            "title": "Video"
        },
        "bitrate": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "rate_control_mode": {
                    "type": "string",
                    "title": "Rate control mode",
                    "oneOf": [
                        {"const" : "constant_quality", "title": "Constant quality"},
                        {"const" : "variable_bitrate", "title": "Variable bitrate"},
                        {"const" : "constant_bitrate", "title": "Constant bitrate"}
                    ]
                }
            },
            "dependencies": {
                "rate_control_mode": {
                    "oneOf": [
                        {
                            "properties": {
                                "rate_control_mode":  {
                                    "const": "constant_quality"
                                },
                                "crf": {
                                    "title":   "CRF",
                                    "type":    "number",
                                    "decimal": false
                                },
                                "buffer_size": {
                                    "title":   "Buffer size",
                                    "type":    "number",
                                    "decimal": false
                                }
                            },
                            "required":   [
                                "crf",
                                "buffer_size"
                            ]
                        },
                        {
                            "properties": {
                                "rate_control_mode":  {
                                    "const": "variable_bitrate"
                                },
                                "max_bitrate": {
                                    "title":   "Max bit rate",
                                    "type":    "number",
                                    "decimal": false
                                },
                                "avg_bitrate": {
                                    "title":   "Avg bit rate",
                                    "type":    "number",
                                    "decimal": false
                                },
                                "min_bitrate": {
                                    "title":   "Min bit rate",
                                    "type":    "number",
                                    "decimal": false
                                },
                                "buffer_size": {
                                    "title":   "Buffer size",
                                    "type":    "number",
                                    "decimal": false
                                }
                            },
                            "required":   [
                                "max_bitrate",
                                "min_bitrate",
                                "avg_bitrate",
                                "buffer_size"
                            ]
                        },
                        {
                            "properties": {
                                "rate_control_mode":  {
                                    "const": "constant_bitrate"
                                },
                                "target_bitrate": {
                                    "title":   "Target bit rate",
                                    "type":    "number",
                                    "decimal": false
                                }
                            },
                            "required":   [
                                "target_bitrate"
                            ]
                        }
                    ]
                }
            },
            "required": [
                "rate_control_mode"
            ],
            "title": "Bitrate"
        },
        "frame_rate": {
            "type": "object",
            "additionalProperties": true,
            "properties": {
                "fps": {
                    "type": "string",
                    "default": "",
                    "oneOf": [
                        {"const" : "", "title": "Same as Source"},
                        {"const" : "25", "title": "25"},
                        {"const" : "29.97", "title": "29.97"},
                        {"const" : "30", "title": "30"},
                        {"const" : "59.94", "title": "59.94"},
                        {"const" : "60", "title": "60"},
                        {"const" : "custom", "title": "Custom"}
                    ]
                },
                "up_mode": {
                    "title": "Up-conversion mode",
                    "type": "string",
                    "default": "avoid",
                    "oneOf": [
                        {"const" : "avoid", "title": "Avoid up-conversion"},
                        {"const" : "duplicate", "title": "Duplicate frames"},
                        {"const" : "interpolate", "title": "Interpolate frames"}
                    ]
                }
            },
            "dependencies": {
                "fps": {
                    "oneOf": [
                        {
                            "properties": {
                                "fps":        {
                                    "const": "custom"
                                },
                                "fps_custom": {
                                    "title":   "Custom fps",
                                    "type":    "number",
                                    "decimal": false
                                }
                            },
                            "required":   [
                                "fps_custom"
                            ]
                        },
                        {
                            "properties": {
                                "fps": {
                                    "not": {"const": "custom"}
                                }
                            },
                            "required":   []
                        }
                    ]
                }
            },
            "required": [
                "fps",
                "up_mode"
            ],
            "title": "FrameRate"
        }
    }
}

export const uiSchema = {
    //"ui:field": "tabs",
    "ui:noTitle": true,
    "ui:options":  {
        "expandable": false
    },
    "outputs": {
        "ui:noTitle": true,
        "ui:buttonText": "Add output",
        "items": {
            "ui:title": "Output",
            "ui:options" : {
                "title" : {
                    "props" : {
                        "variant" : "h3",
                        "className" : "color-blue1",
                    },
                    "customProps" : {
                        "customTitle" : true,
                    }
                }
            },
            "ui:grid": {
                "xs": 12,
                "md": 9
            },
            "output_type": {
                "ui:options" : {
                    "props" : {
                        "color" : "secondary",
                        "label" : "Container",
                        "shrinkLabel" : "Container"
                    }
                },
                "ui:style": {
                    "alignSelf": "start",
                },
                "ui:grid": {
                    "xs": 6,
                    "md": 3
                }
            },
            "output_params": {
                "ui:order": [
                    "aspect", "video", "audio","filters",
                ],
                "ui:layout": "tabs",
                "ui:grid": {
                    "xs": 12,
                    "md": 9
                },
                "aspect": {
                    "ui:noTitle": true,
                    "ui:classNames": "inline-form",
                    "cropping": {
                        "ui:options" : {
                            "title" : {
                                "props" : {
                                    "color" : "secondary.dark",
                                    "variant" : "label1",
                                },
                            },
                            "customProps" : {
                                "hasDivider" : true,
                                "infoPopUp" : {
                                    "title" : "Cropping",
                                    "definition" : "Crops the frame size to match a smaller output rectangle.",
                                    "mandatory" : false,
                                    "params" : [
                                        [
                                            {
                                                text : "Bottom: bottom edge shift upwards as a percentage of frame height | "
                                            },
                                            {
                                                italic: true,
                                                text : "range: "
                                            },
                                            {
                                                italic: true,
                                                bold: true,
                                                text : "0-50"
                                            },
                                            {
                                                italic: true,
                                                text : " | default: "
                                            },
                                            {
                                                italic: true,
                                                bold: true,
                                                text : "0"
                                            }
                                        ],

                                        [
                                            {
                                                text : "Left: left edge shift rightward as a percentage of frame width | "
                                            },
                                            {
                                                italic: true,
                                                text : "range: "
                                            },
                                            {
                                                italic: true,
                                                bold: true,
                                                text : "0-50"
                                            },
                                            {
                                                italic: true,
                                                text : " | default: "
                                            },
                                            {
                                                italic: true,
                                                bold: true,
                                                text : "0"
                                            }
                                        ],

                                        [
                                            {
                                                text : "Right: right edge shift leftward as a percentage of frame width | "
                                            },
                                            {
                                                italic: true,
                                                text : "range: "
                                            },
                                            {
                                                italic: true,
                                                bold: true,
                                                text : "0-50"
                                            },
                                            {
                                                italic: true,
                                                text : " | default: "
                                            },
                                            {
                                                italic: true,
                                                bold: true,
                                                text : "0"
                                            }],

                                        [
                                            {
                                                text : "Top: top edge shift downwards as a percentage of frame height | "
                                            },
                                            {
                                                italic: true,
                                                text : "range: "
                                            },
                                            {
                                                italic: true,
                                                bold: true,
                                                text : "0-50"
                                            },
                                            {
                                                italic: true,
                                                text : " | default: "
                                            },
                                            {
                                                italic: true,
                                                bold: true,
                                                text : "0"
                                            }
                                        ]
                                    ],
                                    "notes" : []
                                }
                            }
                        },
                        "ui:classNames": "inline-form",
                        "ui:grid": {
                            "xs": 12,
                            "sm": 3
                        },
                        "bottom" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                            "ui:grid" : {
                                "xs" : 3,
                                "md" : 2
                            }
                        },
                        "left" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                            "ui:grid" : {
                                "xs" : 3,
                                "md" : 2
                            }
                        },
                        "right" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                            "ui:grid" : {
                                "xs" : 3,
                                "md" : 2
                            }
                        },
                        "top" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                            "ui:grid" : {
                                "xs" : 3,
                                "md" : 2
                            }
                        },
                    },
                    "orientation": {
                        "ui:options" : {
                            "title" : {
                                "props" : {
                                    "color" : "secondary.dark",
                                    "variant" : "label1",
                                }
                            },
                            "customProps" : {
                                "hasDivider" : true,
                                "infoPopUp" : {
                                    "title" : "Orientation",
                                    "definition" : "Flips and/or rotates the video.",
                                    "mandatory" : false,
                                    "params" : [
                                        [
                                            {
                                                text : "Flip",
                                                subTitle : true
                                            }
                                        ],

                                        [
                                            {
                                                text : "None: no flip  "
                                            },
                                            {
                                                italic: true,
                                                text : "(default); "
                                            }
                                        ],

                                        [
                                            {
                                                text : "Horizontal: inverts the frame’s x-axis;"
                                            }
                                        ],

                                        [
                                            {
                                                text : "Vertical: inverts the frame’s y-axis;"
                                            }
                                        ],

                                        [
                                            {
                                                text : "Rotation",
                                                subTitle : true
                                            }
                                        ],

                                        [
                                            {
                                                text : "None: no rotation "
                                            },
                                            {
                                                italic: true,
                                                text : "(default); "
                                            }
                                        ],

                                        [
                                            {
                                                text : "90° clockwise: rotates the frame by 90 degrees clockwise;"
                                            }
                                        ],

                                        [
                                            {
                                                text : "90° counter-clockwise: rotates the frame by 90 degrees counter-clockwise;"
                                            }
                                        ],

                                        [
                                            {
                                                text : "180°: rotates the frame by 180 degrees."
                                            }
                                        ],

                                    ],
                                    "notes" : [
                                        [
                                            {
                                                text : "Flipping occurs before rotation;"
                                            }
                                        ],
                                        [
                                            {
                                                text : "Any orientation changes are applied "
                                            },
                                            {
                                                italic: true,
                                                text : "after "
                                            },
                                            {
                                                text : "resolution scaling."
                                            }
                                        ],
                                    ]
                                }
                            }
                        },
                        "flip" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                    "label" : "Select flip",
                                    "shrinkLabel" : "Flip"
                                }
                            },
                            "ui:grid" : {
                                "xs" : 5,
                                "md" : 4
                            }
                        },
                        "rotation" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                    "label" : "Rotation",
                                    "shrinkLabel" : "Rotation"
                                }
                            },
                            "ui:grid" : {
                                "xs" : 5,
                                "md" : 4
                            }
                        },
                        "ui:grid": {
                            "xs": 12,
                            "sm": 4,
                            "md": 3
                        },
                    },
                    "resolution": {
                        "ui:options" : {
                            "title" : {
                                "props" : {
                                    "color" : "secondary.dark",
                                    "variant" : "label1",
                                }
                            },
                            "customProps" : {
                                "hasDivider" : true,
                                "infoPopUp" : {
                                    "title" : "Resolution & Aspect Ratio",
                                    "definition" : "Resizes the frame dimensions to a desired height and width.",
                                    "mandatory" : true,
                                    "params" : [
                                        [
                                            {
                                                text : "height: the output frame height in pixels | ",
                                            },
                                            {
                                                text : "range: ",
                                                italic: true
                                            },
                                            {
                                                text : "0-9999;",
                                                italic: true,
                                                bold: true
                                            },
                                        ],

                                        [
                                            {
                                                text : "(width): the custom output frame width in pixels | ",
                                            },
                                            {
                                                text : "range: ",
                                                italic: true
                                            },
                                            {
                                                text : "0-9999;",
                                                italic: true,
                                                bold: true
                                            },
                                        ],

                                        [
                                            {
                                                text : "Aspect ratio: the output frame aspect ratio as width divided by height;",
                                            },
                                        ],

                                        [
                                            {
                                                text : "(Avoid upscaling): if checked, the output frame dimensions will be the minimum between the specified resolution and the input frame resolution | ",
                                            },
                                            {
                                                text : "default: ",
                                                italic: true
                                            },
                                            {
                                                text : "Off.",
                                                italic: true,
                                                bold: true
                                            },
                                        ],


                                    ],
                                    "notes" : []
                                }
                            }
                        },
                        "ui:grid": {
                            "xs": 12,
                            "sm": 4,
                            "md": 3
                        },
                        "ui:order": [
                            "custom_height", "custom_aspect_ratio", "height", "aspect_ratio","avoid_upscaling"
                        ],
                        "height": {
                            "ui:widget": "pills",
                            "ui:grid": {
                                "xs": 12
                            }
                        },
                        "custom_height": {
                            "ui:grid": {
                                "xs": 3,
                                "md": 2
                            },
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                        "aspect_ratio": {
                            "ui:widget": "radio",
                            "ui:options" : {
                                "props" : {

                                },
                                "customProps" : {
                                    "formLabelColor" : "secondary.dark",
                                    "formLabelVariant" : "label2",
                                    "controlLabelColor" : "secondary.dark",
                                    "controlVariant" : "caption1",
                                    "isRow" : true
                                }
                            },
                            "ui:grid": {
                                "xs": 9,
                            }
                        },
                        "custom_aspect_ratio": {
                            "ui:grid": {
                                "xs": 3,
                                "md": 2
                            },
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                        "avoid_upscaling": {
                            "ui:grid": {
                                "xs": 3
                            }
                        }
                    },
                    "padding": {
                        "ui:options" : {
                            "title" : {
                                "props" : {
                                    "color" : "secondary.dark",
                                    "variant" : "label1",
                                }
                            },
                            "customProps" : {
                            "infoPopUp" : {
                                "title" : "Padding",
                                "definition" : "Adds equal-sized single-colored stripes to the edges of the frame in order to match a final aspect ratio.",
                                "mandatory" : false,
                                "params" : [
                                    [
                                        {
                                            text : "Final aspect ratio: the target aspect ratio after the padding has been added | "
                                        },
                                        {
                                            text : "default: ",
                                            italic : true,
                                        },
                                        {
                                            text : "Don’t fill; ",
                                            bold : true,
                                        }
                                    ],

                                    [
                                        {
                                            text : "Color: the fill color of the padding | "
                                        },
                                        {
                                            text : "default: ",
                                            italic : true,
                                        },
                                        {
                                            text : "Black ",
                                            bold : true,
                                        }
                                    ]
                                ],
                                "notes" : [
                                    [
                                        {
                                            text : "The padding will be placed in addition to the width or height defined in the Resolution section to match the new aspect ratio."
                                        }
                                    ]
                                ]
                            }
                            }
                        },
                        "ui:grid": {
                            "xs": 12,
                            "sm": 3
                        },
                        "ui:order": [
                            "final_aspect_ratio", "final_aspect_ratio_x", "final_aspect_ratio_y", "fill_color"
                        ],
                        "final_aspect_ratio": {
                            "ui:grid": { "xs": 4, "md": 4 },
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                    "label" : "Final aspect ratio",
                                    "shrinkLabel" : "Final aspect ratio"
                                }
                            },
                        },
                        "fill_color": {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                },
                                "customProps" : {
                                    "isColorPicker" : true
                                }
                            },
                            "ui:grid": { "xs": 6,  "sm": 5, "md": 4, "lg": 3  },
                        },
                        "final_aspect_ratio_x": {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                            "ui:grid": {
                                "xs": 2,
                                "md": 2,
                                "lg": 2
                            },},
                        "final_aspect_ratio_y": {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                            "ui:grid": {
                                "xs": 2,
                                "md": 2,
                                "lg": 2
                            }, },
                    },
                    "ui:order": [
                        "cropping", "orientation", "resolution", "padding"
                    ],
                },
                "video": {
                    "ui:noTitle": true,
                    "codec": {
                        "ui:options" : {
                            "props" : {
                                "color" : "secondary",
                                "label" : "Select codec",
                                "shrinkLabel" : "Codec"
                            },
                            "customProps" : {
                                "infoPopUp" : {
                                    "title" : "Codec",
                                    "definition" : "Selects the video encoder and relative encoding options.",
                                    "mandatory" : true,
                                    "params" : [
                                        [
                                            {
                                                text : "Codec: the output video codec;"
                                            }
                                        ],
                                        [
                                            {
                                                text : "Codec: the output video codec;"
                                            },
                                            {
                                                text : "default: ",
                                                italic : true,
                                            },
                                            {
                                                text : "Auto ",
                                                bold : true,
                                            }
                                        ],
                                        [
                                            {
                                                text : "(Preset): the libx264 or libx265’s preset | "
                                            },
                                            {
                                                text : "default: ",
                                                italic : true,
                                            },
                                            {
                                                text : "medium ",
                                                bold : true,
                                            }
                                        ],
                                        [
                                            {
                                                text : "(Profile): the libx264 or libx265’s profile | "
                                            },
                                            {
                                                text : "default: ",
                                                italic : true,
                                            },
                                            {
                                                text : "Auto ",
                                                bold : true,
                                            }
                                        ],
                                        [
                                            {
                                                text : "(Tune): the libx264 or libx265’s tune | "
                                            },
                                            {
                                                text : "default: ",
                                                italic : true,
                                            },
                                            {
                                                text : "None ",
                                                bold : true,
                                            }
                                        ],
                                        [
                                            {
                                                text : "(CPU used): the libaom-av1 or libvpx-vp9’s CPU used | "
                                            },
                                            {
                                                text : "default: ",
                                                italic : true,
                                            },
                                            {
                                                text : "1 ",
                                                bold : true,
                                            }
                                        ],
                                        [
                                            {
                                                text : "(Auto-alt-ref): the libaom-av1’s auto-alt-ref."
                                            },
                                        ]
                                    ],
                                    "notes" : []
                                }
                            }
                        },
                        "ui:grid": {
                            "xs": 6,
                            "md": 4
                        }
                    },
                    "codec_params": {
                        "ui:noTitle": true,
                        "ui:options" : {
                            "props" : {
                                "color" : "secondary",
                                "label" : "Codec parameters",
                                "shrinkLabel" : "Codec"
                            }
                        },
                        "ui:expandable": true,
                        "ui:grid": {
                            "xs": 6,
                            "md": 4
                        }
                    },
                    "frame_rate": {
                        "ui:options" : {
                            "title" : {
                                "props" : {
                                    "color" : "secondary.dark",
                                    "variant" : "label1",
                                }
                            },
                            "customProps" : {
                                "hasDivider" : true,
                                "infoPopUp" : {
                                    "title": "Frame rate",
                                    "definition": "Adjusts the output frame rate",
                                    "mandatory": false,
                                    "params": [
                                        [
                                            {
                                                text: "FPS: the output frames per seconds | range: 0-144 | "
                                            },
                                            {
                                                text: "default: ",
                                                italic: true,
                                            },
                                            {
                                                text: "Same as source;",
                                                bold: true,
                                            }
                                        ],
                                        [
                                            {
                                                text: "Up-conversion mode: if “Avoid up-conversion” is checked, the output frame rate will be the minimum between the specified frame rate and the input frame rate | "
                                            },
                                            {
                                                text: "default: ",
                                                italic: true,
                                            },
                                            {
                                                text: "Avoid up-conversion.",
                                                bold: true,
                                            }
                                        ],
                                    ],
                                    notes : []
                                }
                            }
                        },
                        "ui:grid": {
                            "xs": 6,
                            "md": 4
                        },
                        "fps" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                    "label" : "Select FPS",
                                    "shrinkLabel" : "FPS"
                                }
                            },
                        },
                        "fps_custom" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                        "up_mode" : {
                            "ui:widget": "radio",
                            "ui:options" : {
                                "customProps" : {
                                    "formLabelColor" : "secondary.dark",
                                    "formLabelVariant" : "label2",
                                    "controlLabelColor" : "secondary.dark",
                                    "controlVariant" : "caption1",
                                    "isRow" : true
                                }
                            },
                            "ui:grid": {
                                "xs": 12,
                            }
                        },
                        "ui:order": [
                            "fps", "fps_custom", "up_mode"
                        ],
                    },
                    "bitrate": {
                        "ui:options" : {
                            "title" : {
                                "props" : {
                                    "color" : "secondary.dark",
                                    "variant" : "label1",
                                }
                            },
                            "subtitle" : {
                                "value" : "Enter values in bits, or add suffix “k” / “M” for kilobits and Megabits",
                                "props" : {
                                    "variant" : "subtitle2"
                                }
                            },
                            "customProps" : {
                                "infoPopUp" : {
                                    "title": "Bitrate",
                                    "definition": "Allows to control the output bitrate and quality with three different bitrate control modes.",
                                    "mandatory": true,
                                    "params": [
                                        [
                                            {
                                                text: "Constant quality",
                                                subTitle: true,
                                            },
                                            {
                                                text: "Keeps the frame quality constant, adapting the bitrate accordingly",
                                                subSubTitle : true
                                            }
                                        ],
                                        [
                                            {
                                                text: "Buffer size: the buffer size in bits;"
                                            }
                                        ],
                                        [
                                            {
                                                text: "CRF: the Constant Rate Factor | "
                                            },
                                            {
                                                text: "range: ",
                                                italic: true,
                                            },
                                            {
                                                text: " 1-51;",
                                                bold: true,
                                            }
                                        ],

                                        [
                                            {
                                                text: "Variable bitrate",
                                                subTitle: true,
                                            },
                                            {
                                                text: "Sets the bitrate around an average value and prevents bitrates above or below thresholds",
                                                subSubTitle : true
                                            }
                                        ],
                                        [
                                            {
                                                text: "Avg bitrate: the average bitrate in bits;"
                                            }
                                        ],
                                        [
                                            {
                                                text: "Buffer size: the buffer size in bits;"
                                            }
                                        ],
                                        [
                                            {
                                                text: "Max bitrate: the maximum bitrate in bits;"
                                            }
                                        ],
                                        [
                                            {
                                                text: "Min bitrate: the minimum bitrate in bits;"
                                            }
                                        ],

                                        [
                                            {
                                                text: "Constant bitrate",
                                                subTitle: true,
                                            },
                                            {
                                                text: "Keeps the bitrate always close to the specified value",
                                                subSubTitle : true
                                            }
                                        ],
                                        [
                                            {
                                                text: "Target bitrate: the target bitrate in bits;"
                                            }
                                        ],
                                    ],
                                    notes : []
                                }
                            }
                        },
                        "ui:expandable": false,
                        "ui:grid": {
                            "xs": 6,
                            "md": 4
                        },
                        "rate_control_mode" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                    "label" : "Rate control mode",
                                    "shrinkLabel" : "Rate control mode"
                                }
                            },
                        },
                        "target_bitrate" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                        "buffer_size" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                        "crf" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                        "max_bitrate" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                        "avg_bitrate" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                        "min_bitrate" : {
                            "ui:options" : {
                                "props" : {
                                    "color" : "secondary",
                                }
                            },
                        },
                    },
                    "ui:order" : ["codec", "codec_params", "frame_rate", "bitrate"]
                },
                "audio": {
                    "ui:noTitle": true,
                    "audio_codec" : {
                        "ui:options" : {
                            "props" : {
                                "color" : "secondary",
                                "label" : "Codec",
                                "shrinkLabel" : "Codec"
                            }
                        }
                    },
                    "audio_channel" : {
                        "ui:options" : {
                            "props" : {
                                "color" : "secondary",
                                "label" : "Channel",
                                "shrinkLabel" : "Channel"
                            }
                        }
                    },
                    "audio_sample_rate" : {
                        "ui:options" : {
                            "props" : {
                                "color" : "secondary",
                                "label" : "Sample rate",
                                "shrinkLabel" : "Sample rate"
                            }
                        }
                    },
                    "audio_bitrate" : {
                        "ui:inline": true,
                        "ui:options" : {
                            "props" : {
                                "color" : "secondary",
                                "label" : "Bitrate",
                                "shrinkLabel" : "Bitrate"
                            }
                        }
                    },
                    "audio_sample_rate_custom" : {
                        "ui:options" : {
                            "props" : {
                                "color" : "secondary",
                            }
                        },
                    },
                    "audio_bitrate_custom" : {
                        "ui:options" : {
                            "props" : {
                                "color" : "secondary",
                            }
                        },
                    },
                    "ui:order": [
                        "audio_codec", "audio_channel", "audio_sample_rate", "audio_sample_rate_custom", "audio_bitrate", "audio_bitrate_custom"
                    ],
                    "ui:grid": {
                        "xs": 12,
                        "sm": 6,
                        "md": 3
                    }
                },
                "filters": {
                    "ui:noTitle": true
                },
            },
            "name": {"ui:widget": "hidden"},
        }
    }
}

export default () => schema
