import React, {useContext} from 'react';

import { ArrayFieldTemplateProps, IdSchema } from '@rjsf/core';

import {MuiComponentContext} from "@rjsf/material-ui";
import AddButton from "./AddButton";
import {getUiOptions, isMultiSelect} from "@rjsf/core/lib/utils";
import {getDefaultRegistry} from "@rjsf/core/lib/defaultRegistry";
import {Divider, Typography} from "@mui/material";
import {ReactComponent as TrashCan} from "../../assets/jobIcons/trash-can.svg";
import "./../../components/layoutComponents/_layouts.scss"
import Mui5ContextProps from "@rjsf/material-ui/dist/Theme5/Mui5ContextProps";

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    const { schema, registry = getDefaultRegistry() } = props;

    if (isMultiSelect(schema, registry.rootSchema)) {
        return <DefaultFixedArrayFieldTemplate {...props} />;
    } else {
        return <DefaultNormalArrayFieldTemplate {...props} />;
    }
};

interface TitleFieldProps {
    id: string;
    title: string;
    required: boolean;
}

type ArrayFieldTitleProps = {
    TitleField: React.ComponentType<TitleFieldProps>;
    idSchema: IdSchema;
    title: string;
    required: boolean;
};

const ArrayFieldTitle = ({ TitleField, idSchema, title, required }: ArrayFieldTitleProps) => {
    if (!title) {
        return null;
    }

    const id = `${idSchema.$id}__title`;
    return <TitleField id={id} title={title} required={required} />;
};

interface DescriptionFieldProps {
    id: string;
    description: string | React.ReactElement
}

type ArrayFieldDescriptionProps = {
    DescriptionField: React.FunctionComponent<DescriptionFieldProps>;
    idSchema: IdSchema;
    description: string;
};

const ArrayFieldDescription = ({ DescriptionField, idSchema, description }: ArrayFieldDescriptionProps) => {
    if (!description) {
        return null;
    }

    const id = `${idSchema.$id}__description`;
    return <DescriptionField id={id} description={description} />;
};

interface DefaultArrayItemProps {
    key: string;
    numForms?: number;
    children: React.ReactNode;
    disabled?: boolean;
    readonly?: boolean;
    index: number;
    onDropIndexClick: (index: number) => () => void;
}

const DefaultArrayItem = ({ key, numForms, children, disabled, readonly, index, onDropIndexClick }: DefaultArrayItemProps) => {
    const { Box, Grid, IconButton, Paper } = useContext(MuiComponentContext) as Mui5ContextProps;
    const btnStyle = {
        flex: 1,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        minWidth: 0,
    };

    const hasFormData =
      children &&
      React.isValidElement(children) &&
      children.props &&
      children.props.formData &&
      Object.keys(children.props.formData).length > 0;

    return (
      <Grid container={true} key={key} alignItems="center">
          <Grid item={true} xs={12}>
              <Box mb={2} className={numForms && numForms > 1 ? 'borderColor-pink3 box-output-params' : ''}>
                  <Paper elevation={0}>
                      <Box>{children}</Box>
                      <Grid container>
                          <Grid item xs={0} md={3}></Grid>
                          <Grid item xs={12} md={9}>
                              <Box mt={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                  {hasFormData && (
                                    <Box>
                                        <IconButton
                                          size="small"
                                          tabIndex={-1}
                                          style={btnStyle as React.CSSProperties}
                                          disabled={disabled || readonly}
                                          onClick={onDropIndexClick(index)}
                                        >
                                            <TrashCan />
                                        </IconButton>
                                    </Box>
                                  )}
                              </Box>
                              {!(numForms && numForms > 1) && hasFormData && (
                                <Box mt={1}>
                                    <Divider color="secondary" />
                                </Box>
                              )}
                          </Grid>
                      </Grid>
                  </Paper>
              </Box>
          </Grid>
      </Grid>
    );
};

const DefaultFixedArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    const uiOptions = getUiOptions(props.uiSchema) as {noTitle: boolean; buttonText: string};

    return (
        <fieldset className={props.className}>
            <ArrayFieldTitle
                key={`array-field-title-${props.idSchema.$id}`}
                TitleField={props.TitleField}
                idSchema={props.idSchema}
                title={props.uiSchema['ui:title'] || props.title}
                required={props.required}
            />

            {(props.uiSchema['ui:description'] || props.schema.description) && !uiOptions.noTitle && (
                <div className="field-description" key={`field-description-${props.idSchema.$id}`}>
                    {props.uiSchema['ui:description'] || props.schema.description}
                </div>
            )}

            <div className="row array-item-list" key={`array-item-list-${props.idSchema.$id}`}>
                {props.items && props.items.map(DefaultArrayItem)}
            </div>

        </fieldset>
    );
};

const DefaultNormalArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    const { Box, Grid, Paper } = useContext(MuiComponentContext) as Mui5ContextProps;
    const uiOptions = getUiOptions(props.uiSchema);

    const hasFormData = props.formData && props.formData[props.formData.length-1] &&
                          Object.keys(props.formData[props.formData.length-1]).length > 0;

    const numForms = props.formData ? props.formData.length : 0;

    return (
        <Paper elevation={0} >
            <Box >
                {!uiOptions.noTitle ? <ArrayFieldTitle
                    key={`array-field-title-${props.idSchema.$id}`}
                    TitleField={props.TitleField}
                    idSchema={props.idSchema}
                    title={props.uiSchema['ui:title'] || props.title}
                    required={props.required}
                /> : null}

                {(props.uiSchema['ui:description'] || props.schema.description) && (
                    <ArrayFieldDescription
                        key={`array-field-description-${props.idSchema.$id}`}
                        DescriptionField={props.DescriptionField}
                        idSchema={props.idSchema}
                        description={props.uiSchema['ui:description'] || props.schema.description}
                    />
                )}

                <Grid container={true} key={`array-item-list-${props.idSchema.$id}`} justifyContent="space-between">
                    {props.items && props.items.map(p => DefaultArrayItem({ ...p, numForms}))}
                    {props.canAdd && (
                        <Grid container justifyContent="space-between" alignItems="center">
                            {hasFormData &&  <Grid item xs={0} md={3}></Grid>}
                            <Grid item xs={12} md={9}>
                                <Box mt={!hasFormData && numForms === 1 ? 20 : 2}
                                     ml={ numForms > 1 ? "1em" : ""}
                                     className={"box-flex-space-between"}>
                                    {numForms > 0 && <Typography color="secondary.dark" variant="caption1">* These fields are mandatory</Typography>}
                                    {(hasFormData || numForms === 0) && <AddButton
                                        className="array-item-add"
                                        onClick={props.onAddClick}
                                        disabled={props.disabled || props.readonly}
                                        buttonText={uiOptions.buttonText}
                                    />}
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper>
    );
};

export default ArrayFieldTemplate;
