import {ThemeOptions} from "@mui/material/styles";
import {Breakpoint, FormControlProps, InputProps} from "@mui/material";
import {TypographyOptions} from "@mui/material/styles/createTypography";
import React, {InputHTMLAttributes} from "react";
import {CommonProps} from "@mui/material/OverridableComponent";
import {Classes} from "@mui/styles/mergeClasses/mergeClasses";

export interface IThemeOptions extends ThemeOptions {
  sidebar: {
    breakpoint: Breakpoint,
    width: number | string
  }
}

export interface ExtendedTypographyOptions extends TypographyOptions {
  label1: React.CSSProperties;
  label2: React.CSSProperties;
}

export interface OwnerState extends Record<string, unknown> {
  formControl?: FormControlProps
  inputProps?: InputProps & Record<string, unknown> | InputHTMLAttributes<HTMLInputElement> & Record<string, unknown>
  componentsProps?: InputProps & Record<string, any> | InputHTMLAttributes<HTMLInputElement> & Record<string, any>
  active?: boolean
  ownerState?: OwnerState
  className?: Classes | string
  value?: any
}

export type MuiButtonStyleOverride = {
  borderRadius: string;
  textTransform: string;
  width?: string;
  height?: string;
  fontFamily?: string;
  fontStyle?: string;
  fontWeight?: string;
  fontSize?: string;
  lineHeight?: string;
  '&:hover'?: {
    background?: string;
    color?: string;
    borderColor?: string;
  };
  '&:active'?: {
    background?: string;
    color?: string;
    borderColor?: string;
  };
  color?: string;
  borderColor?: string;
  background?: string;
  textDecoration?: string;
  '&:disabled'?: {
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
  };
  ' .icon-path'?: {
    fill?: string;
    stroke?: string;
  }
};

export function checkVariant(ownerState: OwnerState, variant: string) {
  return ownerState && ((ownerState.variant === variant) ||
    (ownerState.formControl && ownerState.formControl.variant === variant) ||
    (ownerState.inputProps && ownerState.inputProps.variant === variant))
}

export function checkColor(ownerState: OwnerState, color: string): boolean | undefined {
  return ownerState && ((ownerState.color === color) ||
    (ownerState.formControl && ownerState.formControl.color === color) ||
    (ownerState.inputProps && ownerState.inputProps.color === color) ||
    (ownerState.ownerState && checkColor(ownerState.ownerState, color)))
}

export function checkDisabled(ownerState: OwnerState) {
  return ownerState && ((ownerState.disabled) ||
    (ownerState.formControl && ownerState.formControl.disabled) ||
    (ownerState.inputProps && ownerState.inputProps.disabled) ||
    (ownerState.componentsProps && ownerState.componentsProps?.input && ownerState.componentsProps.input.disabled))
}

export function checkActive(ownerState: OwnerState): boolean {
  return (ownerState && ((ownerState.active === true) || (ownerState.ownerState && checkActive(ownerState.ownerState)))) || false
}

export function checkCompleted(ownerState: OwnerState): boolean {
  return (ownerState && ((ownerState.completed === true) || (ownerState.ownerState && checkCompleted(ownerState.ownerState)))) || false
}

interface SubvariantOwnerState extends Record<string, unknown> {
  className?: {
    includes: (val: string) => boolean;
  },
  ownerState?: SubvariantOwnerState
  inputProps?: InputProps | InputHTMLAttributes<HTMLInputElement>
  primaryTypographyProps?: CommonProps
}

export function checkSubvariant(ownerState: SubvariantOwnerState, subvariant: string): boolean | undefined {
  return ownerState && (
    (ownerState.className &&
      ownerState.className.includes(subvariant)) ||
    (ownerState.ownerState && checkSubvariant(ownerState.ownerState, subvariant)) ||
    (ownerState.inputProps && ownerState.inputProps.className && ownerState.inputProps.className.includes(subvariant)) ||
    (ownerState.primaryTypographyProps && ownerState.primaryTypographyProps?.className?.includes(subvariant))
  )
}
