import React from 'react';
import {OpenFormOptions, OpenOkOnlyOptions, OpenYesNoOptions} from "./ModalContainer";

export type FormChangeHandler<T> = (field: keyof T, value: T[keyof T]) => Partial<T>
export type FormRenderer<T, U> =  (handleChange: FormChangeHandler<T>,  defaultValues: Partial<T>, options?: U) => React.ReactElement
export type ModalRenderer<T> =  (closeModal: (ok: boolean, data: T) => void) => React.ReactElement

export type PromiseHandlerArgs<T> = {
  ok: boolean;
  data?: T
}

export interface ModalContextType {
  OkOnly: (title: string, text: string|React.ReactElement, options?: OpenOkOnlyOptions) => Promise<PromiseHandlerArgs<object>>;
  Error: (title: string, text: string, options?: OpenOkOnlyOptions) => Promise<PromiseHandlerArgs<object>>;
  YesNo: (title: string, text: string, options?: OpenYesNoOptions) => Promise<PromiseHandlerArgs<object>>;
  Form: <T, U>(title: string, form: FormRenderer<T, U>, options?: OpenFormOptions<T>) => Promise<PromiseHandlerArgs<T>>;
  Custom: <T>(title: string, renderFn: ModalRenderer<T>, options?: OpenFormOptions<T>) => Promise<PromiseHandlerArgs<T>>;
}

const ModalContext = React.createContext<ModalContextType | undefined>(undefined);

export default ModalContext;
