import React, {useState} from 'react';
import { FieldProps } from '@rjsf/core';
import {ReactComponent as CircleInfo} from "../../assets/jobIcons/circle-info.svg";
import {ReactComponent as Xmark} from "../../assets/walletIcons/xmark.svg";
import {Box, Chip, Typography} from "@mui/material";

const modalStyle = {
    boxSizing: "borderBox",
    display: "flex",
    flexDirection: "column",
    alignItems: "flexStart",
    padding: "12px 16px",
    gap: "8px",
    zIndex: 200,
    position: "absolute",
    width: "70%",
    left: "15%",
    top: "0",

    background: "#F9F9F9",
    border: "1px solid #9091FE",
    borderRadius: "16px",
    bgColor: "linear-gradient(0deg, #F9F9F9, #F9F9F9)"
}

const chipMandatory = <Chip label={<Typography>Mandatory</Typography>} variant="outlined" color={"primary"} />

const chipOptional = <Chip style={{backgroundColor : "rgba(217, 217, 255, 1)"}} label={<Typography>Optional</Typography>} variant="outlined" color={"secondary"} />

const TitleField = ({ title, variant, color, className, infoPopUp }: FieldProps) => {

    const [modalOpen, setModalOpen] = useState(false)

    return (
        <Box mt={0}>
            <Typography
                variant={variant}
                color={color}
                className={className}
            >
                <Box display={"flex"} flexDirection={"row"}>
                    {title}
                    {infoPopUp && (<Box ml={1} style={{"cursor" : "pointer"}} onClick={() => setModalOpen(true)}><CircleInfo></CircleInfo></Box>)}
                </Box>
            </Typography>
            {infoPopUp && modalOpen && (<Box sx={modalStyle}>
                <div className={"container-horizontal content-space-between"}>
                    <Typography variant={"h2"} color={"secondary.dark"}>
                        {infoPopUp.title}
                    </Typography>
                    <div className={"container-horizontal content-space-around"}>
                        {infoPopUp.mandatory ? chipMandatory : chipOptional}
                        {<div style={{"cursor" : "pointer", "margin" : "0.3em",marginLeft : "2em", marginRight : "-0.2em"}} onClick={() => setModalOpen(false)}><Xmark></Xmark></div>}
                    </div>
                </div>
                <Typography sx={{ mt: 2 }}>
                    {infoPopUp.definition}
                </Typography>
                {infoPopUp.params && infoPopUp.params.length > 0 ?
                    <Box>
                        <Typography variant={"h3"}>Params</Typography>
                        <ul>{infoPopUp.params.map( (p : any[]) =>
                            {
                                if(p[0].subTitle){
                                    if(p[1] && p[1].subSubTitle){
                                        return <Box ml={-5}>
                                            <Typography variant={"h3"}><i>{p[0].text}</i></Typography>
                                            <Typography>{p[1].text}</Typography>
                                        </Box>
                                    }else{
                                        return <Box ml={-5}>
                                            <Typography variant={"h3"}><i>{p[0].text}</i></Typography>
                                        </Box>
                                    }
                                }else{
                                    return <li><Typography>{
                                        p.map<React.ReactNode>(t => {
                                            if(t.bold && t.italic){
                                                return <span><b><i>{t.text}</i></b></span>
                                            }
                                            if(t.bold){
                                                return <span><b>{t.text}</b></span>
                                            }
                                            if(t.italic){
                                                return <span><i>{t.text}</i></span>
                                            }
                                            return <span>{t.text}</span>})
                                    }</Typography>
                                    </li>
                                }
                            }
                        )}</ul>
                    </Box> : <></>}
                {infoPopUp.notes && infoPopUp.notes.length > 0 ?
                    <Box>
                        <Typography variant={"h3"}>Notes</Typography>
                        <ul>{infoPopUp.notes.map( (p : any[]) =>
                            <li><Typography>{
                                p.map<React.ReactNode>(t => {
                                    if(t.bold && t.italic){
                                        return <span><b><i>{t.text}</i></b></span>
                                    }
                                    if(t.bold){
                                        return <span><b>{t.text}</b></span>
                                    }
                                    if(t.italic){
                                        return <span><i>{t.text}</i></span>
                                    }
                                    return <span>{t.text}</span>})
                            }</Typography>
                            </li>)}</ul>
                    </Box> : <></>}
            </Box>)}
        </Box>
    );
};

export default TitleField;
