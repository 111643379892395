import React from 'react';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from "@mui/material";

import {ReactComponent as Circle} from "../../assets/jobIcons/circle.svg";
import {ReactComponent as CircleDot} from "../../assets/jobIcons/circle-dot.svg";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyProps} from "@mui/material/Typography/Typography";

type Color = TypographyProps['color']

export interface RadioFormControlProps {
    variantFormLabel : Variant;
    variantControlLabel : Variant;
    colorFormLabel : Color;
    colorControlLabel?: string; // Color;
    onChange : any;
    onBlur : any;
    onFocus : any
    row? : boolean;
    required : boolean;
    label : string;
    id : string;
    value? : string;
    options : any;
    disabled : boolean;
    readonly : boolean;
    className? : string;
    labelMargin? : number;
    children?: React.ReactNode;
}

const RadioFormControl = ({...props}: RadioFormControlProps) => {

    const { onChange, onBlur, onFocus, row, label, required, id, value, options, disabled, readonly } = props;
    const { variantFormLabel, variantControlLabel, colorFormLabel, colorControlLabel, className, labelMargin} = props;
    const { enumOptions, enumDisabled } = options;

    return (
        <FormControl fullWidth className={className || ''}>
            <FormLabel required={required} htmlFor={id}>
                <Typography mx={labelMargin || 0}
                    variant={variantFormLabel} color={colorFormLabel}>
                    {label}
                </Typography>
            </FormLabel>
            <RadioGroup
                value={`${value||''}`}
                row={row||false}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            >
                {(enumOptions as any).map((option: any, i: number) => {
                    const itemDisabled = enumDisabled && (enumDisabled as any).indexOf(option.value) != -1;

                    const radio = (
                        <FormControlLabel
                            color={colorControlLabel}
                            control={
                                <Radio
                                    name={`${id}-${i}`}
                                    color="primary"
                                    key={i}
                                    icon={
                                        <Circle></Circle>
                                    }
                                    checkedIcon={
                                        <CircleDot></CircleDot>
                                    }
                                />}
                            label={
                                <Typography variant={variantControlLabel} color={colorControlLabel}>
                                    {`${option.label}`}
                                </Typography>}
                            value={`${option.value}`}
                            key={i}
                            disabled={disabled || itemDisabled || readonly}
                        />
                    );

                    return radio;
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioFormControl;
