import React, {useContext} from 'react';
import {
    AuthApiKey,
    AuthApiKeyRequest,
    AuthApiKeyResponse,
    AuthConnectorCredentialsResponse,
    AuthListSecretsResponse,
    AuthSignInResponse, DataSourceSecret
} from "../../client";
import {OAuth2Token} from "@badgateway/oauth2-client";

export interface User {
    email: string;
    displayName: string;
    role?: string;
}

export interface AuthContextInterface<T extends User> {
    getToken: () => Promise<string>;
    getIdpServerUrl: () => string;
    getCachedToken: () => string;
    getUserInfo: () => User;
    refreshUserInfo: () => void;
    getApiKey: (body: AuthApiKeyRequest) => Promise<AuthApiKeyResponse>;
    requestSignInCode: (email: string) => Promise<AuthSignInResponse>
    getApiKeys: () => Promise<AuthApiKey[]>;
    revokeApiKey: (id: string) => Promise<void>;
    isAuthenticated: () => boolean;
    isAdmin: () => boolean;
    exchangeCodeForToken: (authorizationCode: string) => Promise<OAuth2Token>
    setToken: (token: OAuth2Token) => Promise<void>
    getLoginUrl: (state?: string) => Promise<string>
    onTokenUpdate: (callback: (e: CustomEvent) => void) => void
    removeTokenUpdateListener: (callback: (e: CustomEvent) => void) => void
    signOut: () => void
    getConnectorCredentials: (connectorName: string) => Promise<AuthConnectorCredentialsResponse>

    listSecrets: () => Promise<DataSourceSecret[]>
    saveSecret: (secret: DataSourceSecret, secretKey: string) => Promise<void>
    deleteSecret: (secretType: string, secretId: string) => Promise<void>
    //signOut: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextInterface<any>|null>(null);

export const useAuthContext = () => useContext(AuthContext)

export default AuthContext;
