import React, {useContext} from 'react';
import { AddButtonProps } from '@rjsf/core';

import {MuiComponentContext} from "@rjsf/material-ui";

interface CustomAddButtonProps extends  AddButtonProps {
    buttonText?: string
}


const AddButton: React.FC<CustomAddButtonProps> = ({buttonText=null, ...props}) => {
    const { AddIcon, Button } = useContext(MuiComponentContext)

    return (
        <Button {...props} color="primary" variant="outlined">
            <AddIcon /> {buttonText||'Add Item'}
        </Button>
    );
};

export default AddButton;
