import React, {Component, forwardRef, Ref, useContext} from 'react';
import ModalContext, {FormRenderer, ModalContextType, ModalRenderer, PromiseHandlerArgs} from "./context";
import ModalContainer, {OpenFormOptions, TextProps} from "./ModalContainer";
import {Alert} from "@mui/material";


interface ModalProviderProps extends React.ComponentProps<any> {
    defaultText?: TextProps
}



export class ModalProvider extends Component<ModalProviderProps> {
    private modals: ModalContainer | null;
    constructor(props: ModalProviderProps) {
        super(props);
        this.modals = null
        this.state = {
            openModal: null
        }
    }

    render() {
        const modalsNotReady:  Promise<PromiseHandlerArgs<object>> = new Promise((resolve, reject) => {
            reject({message: 'not ready'})
        })
        const provider: ModalContextType = {
            OkOnly: (title, text, options={}) => this.modals?.openOkOnly(title, text, options)||modalsNotReady,
            Error: (title, text, options={}) => this.modals?.openError(title, text, options)||modalsNotReady,
            YesNo: (title, text, options={}) => this.modals?.openYesNo(title, text, options)||modalsNotReady,
            Form: <T,>(title:string, form: FormRenderer<T>, options?: OpenFormOptions<T>) => this.modals?.openForm(title, form, options||{})||modalsNotReady,
            Custom: <T,> (title: string, renderFn: ModalRenderer<T>, options={}) => this.modals?.openCustom(title, renderFn, options)||modalsNotReady,
        }
        return (
            <>
                <ModalContext.Provider value={provider}>
                    {this.props.children}
                </ModalContext.Provider>
                <ModalContainer defaultText={this.props.defaultText} ref={r => {this.modals = r} } provider={provider} />
            </>
        )
    }
}

export const withModals = <P extends object>(Component: React.ComponentType<P & { modals: ModalContextType }>) => {
    //TODO: <Alert color="error">Modals unsupported</Alert> for debug
    return forwardRef((props: P, ref: Ref<any>) => (
      <ModalContext.Consumer>
          {(modals?: ModalContextType) => {
              return modals ? <Component ref={ref} modals={modals} {...props} /> : <></>;
          }}
      </ModalContext.Consumer>
    ));
};

export const useModals = () => useContext(ModalContext)

export default withModals;
