import * as React from "react";
import get from 'lodash/get';
import { FieldProps, useRecordContext } from 'react-admin';
import { useTheme } from "@mui/material";
import StateField from "./StateField";
import { Theme } from "@mui/material/styles";

export enum AccessCodeState {
  NEW = 0,
  ACTIVE = 10,
  REDEEMED = 20,
  REVOKED = 30
}



export const getLabelColor = (value: AccessCodeState, theme: Theme) => {
  switch (value) {
    case AccessCodeState.NEW:
      return theme.palette.info.light
    case AccessCodeState.ACTIVE:
      return theme.palette.primary.main
    case AccessCodeState.REDEEMED:
      return theme.palette.success.light
    case AccessCodeState.REVOKED:
      return theme.palette.error.main
    default:
      return theme.palette.grey.A400
  }
}

const AccessCodeStateField = (props: FieldProps) => {
  const theme = useTheme();
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source || '')
  const label = (AccessCodeState[value] || '').toLowerCase()
  return <StateField label={label} color={getLabelColor(value, theme)} />
}
export default AccessCodeStateField
