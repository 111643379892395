import React from 'react';
import { WidgetProps } from '@rjsf/core';

import {getUiOptions} from "@rjsf/core/lib/utils";

import RadioFormControl from "../formComponents/RadioFormControl";

const RadioWidget = ({
                         id,
                         schema,
                         uiSchema,
                         options,
                         value,
                         required,
                         disabled,
                         readonly,
                         label,
                         onChange,
                         onBlur,
                         onFocus,
                     }: WidgetProps) => {

    const _onChange = (e, value: any) => onChange(schema.type == 'boolean' ? value !== 'false' : value);
    const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

    const row = options ? options.inline : false;

    const uiOptions = getUiOptions(uiSchema);
    const radioCustomProps = uiOptions.customProps;
    const colorFormLabel = radioCustomProps && radioCustomProps.formLabelColor ? radioCustomProps.formLabelColor : "primary";
    const variantFormLabel = radioCustomProps && radioCustomProps.formLabelVariant ? radioCustomProps.formLabelVariant : "label2";
    const colorControlLabel = radioCustomProps && radioCustomProps.controlLabelColor ? radioCustomProps.controlLabelColor : "primary";
    const variantControlLabel = radioCustomProps && radioCustomProps.controlVariant ? radioCustomProps.controlVariant : "caption2";


    return (
       <RadioFormControl value={value}
                         required={required}
                         disabled={disabled}
                         readonly={readonly}
                         options={options}
                         onChange={_onChange}
                         onBlur={_onBlur}
                         onFocus={_onFocus}
                         row={row || (radioCustomProps && radioCustomProps.isRow)}
                         colorFormLabel={colorFormLabel}
                         variantFormLabel={variantFormLabel}
                         colorControlLabel={colorControlLabel}
                         variantControlLabel={variantControlLabel}
                         label={label}
                         id={id}

       >
       </RadioFormControl>
    );
};

export default RadioWidget;
