import React from 'react';
import { WidgetProps } from '@rjsf/core';
import {getUiOptions, schemaRequiresTrueValue} from "@rjsf/core/lib/utils";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";

//const { schemaRequiresTrueValue } = utils;

const CheckboxWidget = (props: WidgetProps) => {
    const { schema, uiSchema, id, value, disabled, readonly, label, autofocus, onChange, onBlur, onFocus } = props;

    // Because an unchecked checkbox will cause html5 validation to fail, only add
    // the "required" attribute if the field value must be "true", due to the
    // "const" or "enum" keywords
    const required = schemaRequiresTrueValue(schema);

    const _onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onChange(checked);
    const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLButtonElement>) => onBlur(id, value);
    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLButtonElement>) => onFocus(id, value);

    const uiOptions = getUiOptions(uiSchema);
    const checkboxProps = uiOptions.props;

    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={id}
                    checked={typeof value === 'undefined' ? false : Boolean(value)}
                    required={required}
                    disabled={disabled || readonly}
                    autoFocus={autofocus}
                    onChange={_onChange}
                    onBlur={_onBlur}
                    onFocus={_onFocus}
                    {...checkboxProps}
                />
            }
            label={
                <Typography variant={"caption1"} color={"secondary.dark"}>
                    {label || ''}
                </Typography>
            }
        />
    );
};

export default CheckboxWidget;
