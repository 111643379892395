import React, {Component} from "react";
import {Box, BreakpointOverrides, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, PaperProps} from "@mui/material";
import Typography from "@mui/material/Typography";
import {StyledComponentProps} from "@mui/styles";
import {OverridableStringUnion} from "@mui/types";
import {FormChangeHandler, FormRenderer, ModalContextType, ModalRenderer, PromiseHandlerArgs} from "./context";
import {ReactComponent as IconTopUp} from "../../assets/walletIcons/icon-top-up.svg"
import {Close} from "@mui/icons-material";
import TopUpForm from "../../components/formComponents/TopUpForm";
import {ButtonPropsVariantOverrides} from "@mui/material/Button/Button";

const MODALS_DELAY_TIME = 100

export type TextProps = {
  okOnly: {
    ok: string;
  };
  yesNo: {
    cancel: string;
    confirm: string;
  };
  form: {
    submit: string;
    cancel: string;
  };
  stepper: {
    continue: string;
  };
};

interface ModalContainerProps extends StyledComponentProps {
  defaultText?: TextProps
  provider: ModalContextType
}

type ButtonVariant = OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>
type AlignPosition = 'left'|'center'|'right'

type ModalContainerState = {
  open: boolean;
  loaded: boolean;
  title: string | null;
  button?: (state?: ModalContainerState, handleResolve?: (data: object | string) => void, handleReject?: (data: object | string) => void) => React.ReactNode | null;
  content?: ((state: ModalContainerState) => React.ReactNode | React.Component | React.ElementType);
  onClose: (() => void) | null;
  size: OverridableStringUnion<"xs" | "sm" | "md" | "lg" | "xl", BreakpointOverrides>;
  scroll?: "paper" | "body";
  clickOutside: boolean;
  counter: number;
  formValid: boolean;
  disableTypography?: boolean;
  closeBtnProps: Record<string, unknown>;
  fullWidth: boolean;
  variant?: 'standard' | 'outlined' | 'contained' | undefined;
  closeable: boolean;
  disabled?: boolean;
  saving?: boolean;
  iconHeader?: boolean;
  iconHeaderComponent?: React.ReactElement;
  alignButtons?: AlignPosition
  errors: string[];
};



export interface BaseModalOptions {
  size?: OverridableStringUnion<"xs" | "sm" | "md" | "lg" | "xl", BreakpointOverrides>;
  scroll?: 'paper' | 'body';
  disableTypography?: boolean;
  alignButtons?: AlignPosition
  iconHeader?: boolean;
}

export interface OpenOkOnlyOptions extends BaseModalOptions {
  variant?: ButtonVariant;
  confirmText?: string;
  [key: string]: unknown;
}

export interface OpenYesNoOptions extends BaseModalOptions {
  variantNo?: ButtonVariant;
  variantYes?: ButtonVariant;
  colorNo?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  colorYes?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  variant?: 'standard' | 'outlined' | 'contained' | undefined;
  confirmText?: string;
  cancelText?: string;
  hideConfirm?: boolean;
  beforeSubmit?: (() => void) | null;
  fullWidth?: boolean;

  [key: string]: unknown;
}

export interface OpenFormOptions<T> extends BaseModalOptions {
  variant?: 'standard' | 'outlined' | 'contained' | undefined;
  submitText?: string;
  cancelText?: string;
  beforeSubmit?: ((data: object, callback: (formData: object) => void) => void) | null;
  submitProps?: Record<string, unknown>;
  defaultValues?: Partial<T>;

  [key: string]: unknown;
}

export interface OpenCustomOptions extends BaseModalOptions {
  [key: string]: unknown;
}


class ModalContainer extends Component<ModalContainerProps, ModalContainerState> {
  counter: number;
  private promise?: Promise<any>;
  private text: TextProps;
  private resolve?: (data: object | string | boolean) => void;
  private reject?: (data: object | string | boolean) => void;
  private callback?: () => void;

  //private form: any;

  constructor(props: ModalContainerProps) {
    super(props);
    this.counter = 0

    this.getDefaultState.bind(this)
    this.openOkOnly.bind(this)
    this.openYesNo.bind(this)
    this.openForm.bind(this)

    this.handleExit.bind(this)
    this.handleResolve.bind(this)
    this.handleReject.bind(this)

    this.state = this.getDefaultState()
    this.promise = undefined

    this.text = Object.assign({
      //TODO: check if it still works as intended
      form: {
        submit: 'Submit',
        cancel: 'Cancel',
      },
      okOnly: {
        ok: 'Ok'
      },
      stepper: {
        continue: 'Continue'
      },
      yesNo: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      }
    }, props.defaultText || {})


  }

  handleExit() {
    this.counter++
    this.setState(this.getDefaultState(), () => {
      setTimeout(() => {
        this.callback && this.callback()

        this.promise = undefined
        this.resolve = undefined
        this.reject = undefined
        this.callback = undefined
      }, MODALS_DELAY_TIME)
    })
  }

  handleResolve<T>(data: PromiseHandlerArgs<T>) {
    this.callback = () => this.resolve && this.resolve(data)
    this.setState({open: false})
    this.handleExit()
  }

  handleReject(data: PromiseHandlerArgs<any>) {
    this.callback = () => this.reject && this.reject(data)
    this.setState({open: false})
    this.handleExit()
  }

  getDefaultState(): ModalContainerState {
    return {
      open: false,
      loaded: false,
      title: null,
      button: undefined,
      onClose: null,
      size: 'md',
      clickOutside: false,
      counter: this.counter,
      formValid: false,
      disableTypography: false,
      closeBtnProps: {},
      fullWidth: false,
      closeable: true,
      errors: []
    }
  }

  openOkOnly(title: string, text: string | React.ReactElement, {size = 'sm', variant='outlined', alignButtons, ...options}: OpenOkOnlyOptions) {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
    //error={variant === 'error'}
    this.setState({
      title: title,
      button: () => <Button onClick={() => this.handleResolve({ok: true})}
                            color="primary" variant={variant} autoFocus>{options.confirmText || this.text.okOnly.ok}</Button>,
      onClose: () => this.handleResolve({ok: false}),
      content: () => options.disableTypography || typeof text !== 'string' ? text : <Typography align={"center"} variant={'subtitle1'}>{text}</Typography>,
      loaded: true,
      open: true,
      size: size,
      iconHeader: options.iconHeader,
      alignButtons: alignButtons,
      //...options
    })
    return this.promise
  }

  openYesNo(title: string, text: string, {
    variantNo = 'outlined',
    variantYes = 'outlined',
    colorNo = 'error',
    colorYes = 'success',
    variant = 'standard',
    size = 'sm',
    confirmText,
    cancelText,
    hideConfirm = false,
    beforeSubmit,
    fullWidth,
    ...options
  }: OpenYesNoOptions): Promise<PromiseHandlerArgs<object>> {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
    this.setState({
      title: title,
      variant: variant,
      fullWidth: fullWidth || false,
      button: (state, handleResolve, handleReject) => <>
        <Button onClick={() => handleReject && handleReject({ok: false})}
                color={colorNo}
                variant={variantNo} autoFocus>{cancelText || this.text.yesNo.cancel}</Button>
        {
          !hideConfirm
            ?
            <Button onClick={() => {
              if (beforeSubmit) {
                new Promise((resolve) => {
                  beforeSubmit();
                  resolve(true)
                }).then(() => handleResolve && handleResolve({ok: true}))
              } else {
                handleResolve && handleResolve({ok: true})
              }
            }}
                    color={colorYes} variant={variantYes}
                    autoFocus>{confirmText || this.text.yesNo.confirm}</Button>
            :
            null
        }
      </>,
      onClose: () => this.handleReject({ok: false}),
      content: () => options.disableTypography ? text : <Typography variant={'subtitle1'}>{text}</Typography>,
      loaded: true,
      open: true,
      size: size,
      ...options
    })
    return this.promise
  }


  openForm<T>(title: string, form: FormRenderer<T>, {
    variant = 'standard',
    size = 'md',
    // scroll = 'body',
    submitText = this.text.yesNo.confirm,
    cancelText = this.text.yesNo.cancel,
    // submitHandler,
    beforeSubmit = null,
    submitProps = {},
    defaultValues,
    disableTypography,
    ...options
  }: OpenFormOptions<T>) {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })

    // button classes
    // classes={{root: this.props.classes.buttons}}
    const formValues: Partial<T> = {...defaultValues}
    const handleFormChange: FormChangeHandler<T> = (field, value) => {
      formValues[field] = value
      return {...formValues}
    }

    this.setState({
      title: title,
      variant: variant,
      disableTypography: disableTypography||false,
      button: (state) => state?.saving ? <LinearProgress color={'secondary'}/> : <>
        <Button onClick={() => this.handleReject({ok: true})}
                {...submitProps}
                disabled={state?.disabled}
                color="error"
                variant="outlined" autoFocus>{cancelText}</Button>
        <Button disabled={state?.disabled /*|| !state.formValid*/}
                onClick={() => {
                  if (beforeSubmit) {
                    this.setState({disabled: true, saving: true, errors: []})
                    Promise.resolve(beforeSubmit({...formValues}, (formData) => {
                      this.setState({disabled: false}, () => {
                        this.handleResolve({data: formData, ok: true})
                      })
                    })).catch(err => {
                      const message = err?.message || `Si è verificato un errore durante l'inserimento del metodo di pagamento`
                      this.setState({disabled: false, saving: false, errors: [message]})
                    })
                  } else {
                    this.handleResolve({data: {...formValues}, ok: true})
                  }
                  //this.handleResolve(true)
                }}
                color="success" variant="contained" autoFocus>{submitText}</Button>
      </>,
      onClose: () => this.handleReject({ok: false}),
      content: () => form(handleFormChange, defaultValues || {}),
      loaded: true,
      open: true,
      size: size,
      //disabled: form.props.disabled,
      saving: false,
      ...options
    })
    return this.promise
  }

  openCustom<T>(title: string, renderFn: ModalRenderer<T>, {
    variant = 'standard',
    size = 'md',
    iconHeader,
    disableTypography,
    ...options
  }: OpenCustomOptions) {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
    const closeModalFn = (ok: boolean, data: T) => {
      if (ok) {
        this.handleResolve({ok, data})
      } else {
        this.handleReject({ok, data})
      }
    }

    this.setState({
      title: title,
      onClose: () => this.handleResolve({ok: false}),
      content: () => renderFn(closeModalFn),
      loaded: true,
      open: true,
      disableTypography: disableTypography||false,
      size: size,
      //variant: variant,
      iconHeader: iconHeader || false,
      //...options
    })
    return this.promise
  }

  openError(title: string, text: string, {size = 'xs', variant='contained', alignButtons='center', ...options}: OpenOkOnlyOptions) {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
    //error={variant === 'error'}
    this.setState({
      title: title,
      button: () => <Button onClick={() => this.handleResolve({ok: true})}
                            color="error" variant={variant} autoFocus>{options.confirmText || this.text.okOnly.ok}</Button>,
      onClose: () => this.handleResolve({ok: false}),
      content: () => <Typography align={"center"} variant={'subtitle1'}>{text}</Typography>,
      loaded: true,
      open: true,
      size: size,
      iconHeader: options.iconHeader,
      alignButtons: alignButtons,
      //...options
    })
    return this.promise
  }
  render() {
    if (!this.state.loaded) {
      return null
    }
    //const sizeClass = this.props.classes[`size${capitalize(this.state.size)}`]
    /*const contentSizeClass = clsx(
      this.props.classes[`content${capitalize(this.state.size)}`],
      this.state.fullWidth ? this.props.classes[`fullWidth${capitalize(this.state.size)}`] : null
    )*/
    //classes={{root: this.props.classes.root, paperWidthLg: this.props.classes.paperWidthLg}}
    //className={sizeClass}
    // className={contentSizeClass}
    // onExited={() => this.handleExit()}
    let buttonsPosition = 'flex-end'
    switch (this.state.alignButtons) {
      case 'left':
        buttonsPosition = 'flex-start'
        break
      case 'right':
        buttonsPosition = 'flex-end'
        break
      case 'center':
        buttonsPosition = 'center'
        break
    }

    const paperProps: Partial<PaperProps<"div">> = {}
    if (this.state.iconHeader) {
      paperProps['className'] = "modal-top-up"
      paperProps['style'] = {overflowY: 'visible'}

      return <Dialog scroll={this.state.scroll} open={this.state.open}
                     PaperProps={paperProps}
                     onClose={this.state.onClose || undefined}
                     maxWidth={this.state.size} fullWidth={true}>
        <div className={"icon-top-up"} style={{
          position: 'absolute',
          alignSelf: 'center',
          top: '-2.5em',
          zIndex: 100,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          {this.state.iconHeaderComponent ? this.state.iconHeaderComponent : <IconTopUp/>}
        </div>
        <DialogContent key={this.state.counter}>
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            {this.state.disableTypography ? this.state.title : <Typography variant="h1">{this.state.title}</Typography>}
            <>{this.state.content && this.state.content(this.state)}</>
            {this.state.errors.map((val, idx) => {
              return <Typography color="error" variant="h4" key={idx}>{val} </Typography>
            })}
          </Box>
        </DialogContent>
        {this.state?.button &&
            <DialogActions  sx={{justifyContent: buttonsPosition, mb:2}}>
              {this.state.button(this.state, (val: any) => this.handleResolve(val), (val: any) => this.handleReject(val))}
            </DialogActions>
        }
      </Dialog>
    }

    return <Dialog scroll={this.state.scroll} open={this.state.open}
                   PaperProps={paperProps}
                   onClose={this.state.clickOutside ? this.state.onClose || undefined : undefined}
                   maxWidth={this.state.size} fullWidth={true}>
      {this.state.iconHeader && <div className={"icon-top-up"}>
          <IconTopUp/>
      </div>
      }
      <DialogTitle>
        {this.state.disableTypography ? this.state.title : <Typography variant="h2">{this.state.title}</Typography>}
        {this.state.closeable ?
          <Box>
            <IconButton color="secondary" disabled={this.state.disabled} onClick={() => this.state.onClose && this.state.onClose()}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: 'gray',
                        }}
                        size='large' {...this.state.closeBtnProps}><Close/></IconButton>
          </Box> : null
        }
      </DialogTitle>
      <DialogContent key={this.state.counter}>
        <>
          {this.state.content && this.state.content(this.state)}
          {this.state.errors.map((val, idx) => {
            return <Typography color="error" variant="h4" key={idx}>{val} </Typography>
          })}
        </>
      </DialogContent>
      <DialogActions sx={{justifyContent: buttonsPosition, mb:2}}>
        {this.state?.button && this.state.button(this.state, (val: any) => this.handleResolve(val), (val: any) => this.handleReject(val))}
      </DialogActions>
    </Dialog>
  }
}

export default ModalContainer
