import {Datagrid, DateField, List, ListActions, ShowButton, TextField, SelectInput, ReferenceField} from "react-admin";
import {DisconnectButton} from "./DisconnectAction";
import React from "react";
import JobStateField from "../../components/RunnerStateField";

const deviceFilters = [
    <SelectInput label="State" source="runner_state"  name="runner_state" choices={[
        {id: 2, name: 'Running'},
        {id: 3, name: 'Busy'},
        {id: 1, name: 'Stopped'},
        {id: 254, name: 'Closed'},
        {id: null, name: 'Unknown'},
    ]}/>,
];

export const DevicesList = () => (
    <List actions={<ListActions/>} storeKey={false} filters={deviceFilters}>
        <Datagrid>
            <TextField source="id"/>
            <JobStateField label="State" source="runner_state"/>
            <TextField source="device_name"/>
            <TextField label="Pool state" source="pool_state"/>
            <TextField label="Pending task" source="pending_task"/>
            <TextField label="Running task" source="running_task"/>
            <TextField source="owner_id"/>
            <DateField showTime={true} source="created_at"/>
            <ShowButton/>
            <DisconnectButton/>
        </Datagrid>
    </List>
);
