import React from 'react';
import { WidgetProps, utils } from '@rjsf/core';
import {getUiOptions} from "@rjsf/core/lib/utils";
import {InputAdornment, TextField} from "@mui/material";
import {ReactComponent as ColorPickerIcon} from "../../assets/jobIcons/color-picker.svg";
import { MuiColorInput } from 'mui-color-input'

const { getDisplayLabel } = utils;

const TextWidget = ({
                        id,
                        placeholder,
                        required,
                        readonly,
                        disabled,
                        type,
                        label,
                        value,
                        onChange,
                        onBlur,
                        onFocus,
                        autofocus,
                        options,
                        schema,
                        uiSchema,
                        rawErrors = [],
                        registry,
                    }: WidgetProps) => {

    const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
        onChange(value === '' ? options.emptyValue : value);
    const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

    const { rootSchema } = registry;
    const displayLabel = getDisplayLabel(schema, uiSchema, rootSchema);
    const inputType = (type || schema.type) === 'string' ? 'text' : `${type || schema.type}`;

    const uiOptions = getUiOptions(uiSchema);
    const textFieldProps = uiOptions.props;
    const textFieldCustomProps = uiOptions.customProps;

    const [color, setColor] = React.useState(value || '#ffffff')


    const handleChange = (color: any) => {
        setColor(color)
        onChange(color)
    }

    return (
        textFieldCustomProps && textFieldCustomProps.isColorPicker ?
            <MuiColorInput
                color={"secondary"}
                format="hex"
                fallbackValue="#ffffff"
                value={color}
                onChange={handleChange}
                /*PopoverProps={
                    {
                        //TODO: https://github.com/viclafouch/mui-color-input/blob/main/src/index.tsx
                        style : {
                            display : pickerOpen ? "block" : "none"
                        }
                    }
                 }*/
                InputProps={{
                    endAdornment : (
                        <InputAdornment position="end">
                            <ColorPickerIcon
                            ></ColorPickerIcon>
                        </InputAdornment>
                    )
                }}
            /> :
            <TextField
                id={id}
                placeholder={placeholder}
                label={displayLabel ? label || schema.title : false}
                autoFocus={autofocus}
                required={required}
                disabled={disabled || readonly}
                type={inputType as string}
                value={value || value === 0 ? value : ''}
                error={rawErrors.length > 0}
                onChange={_onChange}
                onBlur={_onBlur}
                onFocus={_onFocus}
                {...textFieldProps}
            />
    );
};

export default TextWidget;
