import React from 'react';
import {getUiOptions} from "@rjsf/core/lib/utils";
import {Box, Tab, Tabs, Grid, Typography, Divider} from "@mui/material";
import {ObjectFieldTemplateProps} from "@rjsf/core";
import TitleField from "./TitleField";

const ObjectFieldTemplate = ({
                                 DescriptionField,
                                 description,
                                 title,
                                 properties,
                                 required,
                                 uiSchema,
                                 idSchema,
                                 schema,
                                 formData,
                             }: ObjectFieldTemplateProps) => {

    const uiOptions = getUiOptions(uiSchema);
    const pageLayout = uiOptions.layout
    const id = idSchema["$id"]
    const classNames = uiOptions.classNames;
    const grid = {xs: 12, ...uiOptions.grid}

    let titleFieldProps = uiOptions && uiOptions.title && uiOptions.title.props
    const titleFieldCustomProps = uiOptions && uiOptions.title && uiOptions.title.customProps
    const customProps = uiOptions && uiOptions.customProps
    let infoPopUp = customProps && customProps.infoPopUp
    //custom management because in jsonschema codec is set as string and not an object
    if(id === "root_0_output_params_video"){
        title = "Codec"
        titleFieldProps = {
            "color" : "secondary.dark",
            "variant" : "label1",
        }
        infoPopUp = uiSchema && uiSchema.codec && uiSchema.codec["ui:options"] && uiSchema.codec["ui:options"].customProps && uiSchema.codec["ui:options"].customProps.infoPopUp
    }

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (pageLayout) {
        return (
            <div>
                {description}
                <Tabs value={value} onChange={handleChange} aria-label={title}>
                    {properties.map((element: any, index: number) =>
                        <Tab label={element.name} aria-controls={`tabpanel-${index}`} key={`tab-${id}-${index}`} id={`tab-${id}-${index}`}/>)}
                </Tabs>
                {properties.map((element: any, index: number) => {
                        return <Box
                            role="tabpanel"
                            hidden={value !== index}
                            key={`tabpanel-${id}-${index}`}
                            id={`tabpanel-${id}-${index}`}
                            aria-labelledby={`tab-${id}-${index}`}
                            className={`property-wrapper ${classNames}`}>
                            {element.content}
                        </Box>
                    }
                )}

            </div>
        );
    }


    // @ts-ignore
  const filteredProperties = schema && schema.properties ? Object.keys(schema.properties).filter(k => !!schema.properties[k]['__additional_property']) : []

    if (filteredProperties.length > 0) {
        //console.log('render object', title, filteredProperties, formData)
        //filteredProperties.forEach(p => delete schema.properties[p])
        filteredProperties.forEach(p => delete formData[p])
        properties = properties.filter((p) => filteredProperties.indexOf(p.name) === -1)
    }

    const subtitle = uiOptions ? uiOptions.subtitle : undefined;

    return (
        <Box>
            {
                (uiSchema['ui:title'] || title) && !uiOptions.noTitle &&
                !(titleFieldCustomProps && titleFieldCustomProps.customTitle) &&
                (<TitleField id={`${idSchema.$id}-title`} title={title} required={required} {...titleFieldProps} infoPopUp={infoPopUp}/>)
            }
            {id === "root_0_output_params_video" &&
                <Box mt={2}><TitleField id={`${idSchema.$id}-title`} title={title} required={required} {...titleFieldProps} infoPopUp={infoPopUp}/></Box>}
            {
                (subtitle && subtitle.value ? <Typography variant={subtitle.props.variant || "subtitle1"}>{subtitle.value}</Typography> : <></>)
            }
            {description && <DescriptionField id={`${idSchema.$id}-description`} description={description}/>}
            <Grid container={true} spacing={2} className={classNames} mt={0}
                  alignItems="center">
                {properties.map((element, index) => {
                        // Remove the <Grid> if the inner element is hidden as the <Grid>
                        // itself would otherwise still take up space.
                        const elementUiOptions = getUiOptions(element.content.props.uiSchema);
                        const fieldType = element.content.props.schema.type
                        const elementGrid = fieldType !== 'object' ? elementUiOptions.grid || grid : grid
                        const elementStyle = elementUiOptions.style || {}
                        const isHidden = element.content.props.schema.format === 'hidden'
                        return (element.hidden || isHidden) ? (element.content) : (
                            <Grid item={true} {...elementGrid} key={index} style={{...elementStyle}}>
                                {titleFieldCustomProps && titleFieldCustomProps.customTitle ?
                                    (element.name === "output_type" ?
                                            (<Box mt={3} mr={3}>
                                                    <Box mb={2} mt={1}>
                                                        <TitleField id={`${idSchema.$id}-title`}
                                                                    title={title}
                                                                    required={required} {...titleFieldProps}/>
                                                    </Box>
                                                    <Box>
                                                        {element.content}
                                                    </Box>
                                                </Box>
                                            ) :
                                            (<Box>
                                                {element.content}
                                            </Box>)
                                    ) :
                                    (<Box>{element.content}</Box>)
                                }
                            </Grid>
                        )
                    }
                )}
                {customProps && customProps.hasDivider &&
                    <Divider className={"divider-output-param"}></Divider>
                }

                {/*{canExpand(schema, uiSchema, formData) && (
                    <Grid container justifyContent="flex-end">
                        <Grid item={true}>
                            <AddButton
                                className="object-property-expand"
                                onClick={onAddClick(schema)}
                                disabled={disabled || readonly}
                            />
                        </Grid>
                    </Grid>
                )}*/}
            </Grid>
        </Box>
    );
};

export default ObjectFieldTemplate;
